<template>
	<div>
		<sh-bread first="文章管理" second="文章列表"></sh-bread>
		<el-card class="box-card">
			<el-input class="search" placeholder="ID，标题" v-model="query" size="medium" clearable
				suffix-icon="el-icon-search">
			</el-input>
			<el-button type="primary" size="medium" :disabled="isDisable('article','get')" @click="bind()">搜索
			</el-button>
			<el-button @click="openDialog()" type="primary" :disabled="isDisable('article','post')" size="medium">添加
			</el-button>
			<el-button @click="deleteallfile()" type="primary" :disabled="isDisable('article','delete')" size="medium">
				删除所有静态页</el-button>
			<sh-table :loading="loading" :dataList="articleList">
				<el-table-column width="120px" label="封面">
					<template slot-scope="scope">
						<el-image style="width: 90px; height: 70px" :src="scope.row.cover" fit="fill"></el-image>
					</template>
				</el-table-column>
				<el-table-column prop="title" label="标题">
				</el-table-column>
				<el-table-column prop="catalog.name" width="120px" label="栏目">
				</el-table-column>
				<el-table-column prop="ding" width="60px" label="顶">
				</el-table-column>
				<el-table-column prop="reply" width="60px" label="回复">
				</el-table-column>
				<col-createuser />
				<col-createtime />
				<col-modifyuser />
				<col-modifytime />
				<col-enable model="article" />
				<col-excute model="article" width="200px" @openEditDialog="openDialog" @removeByID="bind">
					<template v-slot:slotBtn="slotBtn">
						<el-tooltip effect="dark" content="设置封面" placement="top">
							<el-button @click="openCoverDialog(slotBtn.row._id)" type="warning"
								:disabled="isDisable('article','put')" icon="el-icon-picture-outline" size="mini">
							</el-button>
						</el-tooltip>
					</template>
				</col-excute>
			</sh-table>
			<el-pagination background layout="prev, pager, next, jumper" :page-size=pagesize
				@current-change="handleCurrentChange" :total=allcount>
			</el-pagination>
		</el-card>
		<el-dialog title="设置封面" :visible.sync="coverDialog" :close-on-click-modal="false" width="60%"
			@close="resetCover">
			<el-tabs v-model="activeName" @tab-click="tabClick" type="card">
				<el-tab-pane label="新上传" name="new">
					<div style="display:flex;justify-content:space-around;">
						<ImgCutter v-on:cutDown="cutDown" @onPrintImg="onPrintImg" :tool="true" :cutWidth="180"
							:cutHeight="140" :sizeChange="false" :isModal="false" rate="9:7" :boxWidth="450"
							:boxHeight="350">
							<template v-slot:cancel>
								<el-button @click="coverDialog=false">取消</el-button>
							</template>
						</ImgCutter>
						<div><img :src='ylURL' style="width:180px;height:140px"></div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="历史封面" name="history">
					<el-tabs v-model="catalogName" @tab-click="catalogTabClick">
						<el-tab-pane label="全部" name="all"></el-tab-pane>
						<el-tab-pane v-for="item in catalogList" :key="item._id" :label="item.name" :name="item._id">
						</el-tab-pane>
					</el-tabs>
					<div style="display: flex;flex-wrap:wrap">
						<div class="coverItem" v-for="item in coverList" :key="item._id">
							<el-image :src="item.link" fit="contain" @click="clickImg(item._id)"></el-image>
							<i class="el-icon-delete" @click="delCover(item._id)"></i>
						</div>
					</div>
					<el-pagination small layout="prev, pager, next" :total="coverallcount" :current-page="coverpagenum"
						@current-change="coverCurrentChange">
					</el-pagination>
				</el-tab-pane>
			</el-tabs>
		</el-dialog>
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :close-on-click-modal="false" width="50%"
			@close="resetArticleForm">
			<el-form label-width="80px" :model="articleForm" :rules="articleRule" ref="articleRef">
				<el-row>
					<el-form-item label="标题" prop="title">
						<el-input size="small" placeholder="8~40字符" v-model="articleForm.title"></el-input>
					</el-form-item>
				</el-row>
				<el-row>
					<el-form-item label="正文" prop="content">
						<Wang ref="wang"></Wang>
					</el-form-item>
				</el-row>
				<el-row>
					<el-form-item label="摘要" prop="summary">
						<el-input size="small" type="textarea" rows="4" placeholder="20~100字符"
							v-model="articleForm.summary"></el-input>
					</el-form-item>
				</el-row>
				<el-row>
					<el-form-item label="栏目" prop="catalog">
						<el-select size="small" v-model="articleForm.catalog">
							<el-option v-for="item in catalogList" :key="item.name" :label="item.name"
								:value="item._id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-row>
				<el-row>
					<el-form-item label="标签" prop="tags">
						<el-select size="small" style="width:100%" multiple remote filterable
							:remote-method="remoteMethod" v-model="articleForm.tags">
							<el-option v-for="item in PYtag" :key="item.name" :label="item.name" :value="item.name">
							</el-option>
						</el-select>
					</el-form-item>
				</el-row>
				<el-row>
					<el-form-item label="可用">
						<el-switch v-model="articleForm.enable">
						</el-switch>
					</el-form-item>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible=false">取 消</el-button>
				<el-button type="primary" @click="btnOK">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import ajax from 'utils/ajax'
	import Wang from 'components/common/WEditor.vue'
	import ImgCutter from 'vue-img-cutter'
	import isDisable from 'mixins/isDisable.js'
	import OSS from 'ali-oss'
	import random from 'string-random'
	export default {
		components: {
			Wang,
			ImgCutter
		},
		mixins: [isDisable],
		data() {
			const validateContent = (rule, value, callback) => {
				if (this.$refs.wang.getTxt().trim() === '') {
					callback(new Error('内容不能为空'))
				} else {
					callback()
				}
			}
			return {
				activeName: 'new', // 封面默认选项卡
				catalogName: 'all', // 历史封面默认选项卡
				coverpagesize: 8,
				coverallcount: 0,
				coverpagenum: 1,
				coverList: [], // 封面列表
				coverDialog: false,
				ylURL: '',
				dialogTitle: '添加',
				articleList: [],
				pagesize: 10,
				pagenum: 1,
				allcount: 0,
				loading: false,
				query: '',
				content: '',
				articleForm: {
					title: '',
					summary: '',
					catalog: '',
					enable: true,
					tags: []
				},
				catalogList: [], // 所有栏目
				tagList: [], // 所有的标签
				PYtag: [], // 根据拼音检索出来的标签
				dialogVisible: false,
				articleRule: {
					title: [{
						required: true,
						trigger: 'blur',
						message: '请输入标题'
					}, {
						min: 8,
						max: 40,
						message: '长度在 8 到 40 个字符',
						trigger: 'blur'
					}],
					summary: [{
						required: true,
						trigger: 'blur',
						message: '请输入摘要'
					}, {
						min: 20,
						max: 100,
						message: '长度在 20 到 100 个字符',
						trigger: 'blur'
					}],
					catalog: {
						required: true,
						trigger: 'blur',
						message: '请选择栏目'
					},
					tags: {
						required: true,
						trigger: 'blur',
						message: '请至少选择一个标签'
					},
					content: [{
						validator: validateContent,
						trigger: 'blur'
					}]
				}
			}
		},
		methods: {
			// 将base64转换为file
			dataURLtoBlob: function(dataurl) {
				const arr = dataurl.split(',')
				const mime = arr[0].match(/:(.*?);/)[1]
				const bstr = atob(arr[1])
				let n = bstr.length
				const kzm = dataurl.split(';')[0].split('/')[1]
				const u8arr = new Uint8Array(n)
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n)
				}
				return new File([u8arr], `封面.${kzm}`, {
					type: mime
				})
			},
			openCoverDialog(id) {
				this.coverDialog = true
				this.articleForm.id = id
			},
			resetCover() {
				this.ylURL = ''
				this.catalogList = []
				this.coverList = []
				this.activeName = 'new'
				this.catalogName = 'all'
				delete (this.articleForm.id)
			},
			cutDown(res) {
				const client = new OSS({
					// // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
					// region: 'oss-cn-shanghai',使用别用无需此节点
					// // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
					// 以下是RAM账户hkzj的ID和secret
					accessKeyId: 'LTAI5t7nz86YqfV17Rc9TVYi',
					accessKeySecret: 'AXjC7EGfnskca5C359jB6HhFwKMJPS',
					bucket: 'shuanghei',
					endpoint: 'f.shuanghei.com', // 使用别名访问
					cname: true
				})
				const file = this.dataURLtoBlob(res.dataURL)
				const fileExt = 'jpg'
				const date = new Date()
				const filePath =
					`articlecover/${date.getFullYear()}/${date.getMonth() + 1}/${random(12)}.${fileExt}`
				// 上传阿里云服务器
				client.put(filePath, file)
					.then((res) => {
						res.url = res.url.replace('https', 'http').replace(
							'http', 'https')
						ajax({
								method: 'put',
								url: '/article/updatecover',
								data: { _ID: this.articleForm.id, URL: res.url }
							})
							.then(() => {
								this.coverDialog = false
								this.resetCover()
								this.$message.success('操作成功')
								this.bind()
							})
							.catch((err) => {
								this.$message.error(err.msg)
							})
					}).catch(err => {
						this.$message.error(err.msg)
					})
				// }
				// const formData = new FormData()
				// formData.append('img', file) // key必须是img，否则上传不成功
				// formData.append('type', 'cover')
				// formData.append('_id', this.articleForm.id)
				// ajax({
				// 		method: 'post',
				// 		url: '/article/upload',
				// 		data: formData,
				// 		transformRequest: null // 必须加上这句把默认配置里的取消掉
				// 	})
				// 	.then(() => {
				// 		this.coverDialog = false
				// 		this.resetCover()
				// 		this.$message.success('操作成功')
				// 		this.bind()
				// 	})
				// 	.catch((err) => {
				// 		this.$message.error(err.msg)
				// 	})
			},
			onPrintImg (res) {
				this.ylURL = res.dataURL
			},
			tabClick() { // 变更封面选项卡
				if (this.activeName === 'history') { // 如果是历史封面则绑定数据库
					ajax({
							method: 'get',
							url: '/catalog/enable'
						}).then((res) => {
							this.catalogList = res.data
							this.catalogTabClick()
						})
						.catch((err) => {
							this.$message.error(err.msg)
						})
				}
			},
			catalogTabClick() { // 选择不同目录切换不同目录下的封面
				this.coverpagenum = 1
				this.bindCover()
			},
			clickImg(coverID) { // 点击历史封面后
				ajax({
						method: 'put',
						url: `/article/${this.articleForm.id}/cover/${coverID}`
					}).then(() => {
						this.$message.success('操作成功')
						this.coverDialog = false
						this.bind()
					})
					.catch((err) => {
						this.$message.error(err.msg)
					})
			},
			bindCover() {
				let url = `/cover/${this.coverpagenum}/${this.coverpagesize}`
				if (this.catalogName !== 'all') {
					url = `/cover/${this.coverpagenum}/${this.coverpagesize}?key=${this.catalogName}`
				}
				ajax({
						method: 'get',
						url
					}).then((res) => {
						this.coverList = res.data.list
						this.coverallcount = res.data.allcount
					})
					.catch((err) => {
						this.$message.error(err.msg)
					})
			},
			bind() {
				let url = `/article/${this.pagenum}/${this.pagesize}`
				if (this.query !== '') {
					url = `/article/${this.pagenum}/${this.pagesize}?key=${this.query}`
				}
				ajax({
						method: 'get',
						url
					}).then((res) => {
						this.loading = false
						this.articleList = res.data.list
						this.allcount = res.data.allcount
					})
					.catch((err) => {
						this.$message.error(err.msg)
					})
			},
			handleCurrentChange(val) {
				this.pagenum = val
				this.bind()
			},
			coverCurrentChange(val) {
				this.coverpagenum = val
				this.bindCover()
			},
			resetArticleForm() {
				this.$refs.articleRef.resetFields()
				this.articleForm.enable = true // 不知道为啥resetFields不会重置
				delete (this.articleForm.cover)
				this.catalogList = []
				this.tagList = []
				this.$refs.wang.setHtml('')
				this.$refs.wang.isSource = false
			},
			openDialog(id) {
				const reqList = []
				reqList.push(ajax.get('/catalog/enable'))
				reqList.push(ajax.get('/tag'))
				Promise.all(reqList).then((res) => {
						this.catalogList = res[0].data
						this.tagList = res[1].data
						this.PYtag = this.tagList
					})
					.catch((err) => {
						this.$message.error(err.msg)
					})
				if (id) {
					this.dialogTitle = '修改'
					ajax.get(`/article/${id}`).then((res) => {
							this.articleForm = res.data
							this.$refs.wang.setHtml(res.data.content)
						})
						.catch((err) => {
							this.$message.error(err.msg)
						})
				} else {
					this.dialogTitle = '添加'
					delete (this.articleForm._id)
				}
				this.dialogVisible = true
			},
			remoteMethod(query) { // 标签下拉框，检索
				if (query !== '') {
					this.PYtag = this.tagList.filter(item => {
						return item.PY.toLowerCase()
							.indexOf(query.toLowerCase()) > -1
					})
				} else {
					this.PYtag = this.tagList
				}
			},
			btnOK() {
				this.$refs.articleRef.validate((valid) => {
					if (valid) {
						const data = this.articleForm
						data.content = this.$refs.wang.content
						data.tags = JSON.stringify(data.tags)
						data.content = this.$refs.wang.getHtml()
						let method = 'post'
						if (this.dialogTitle === '修改') method = 'put'
						ajax({
								method,
								url: '/article',
								data
							})
							.then(() => {
								this.$message.success('操作成功')
								this.resetArticleForm()
								this.dialogVisible = false
								this.bind()
							})
							.catch((err) => {
								this.$message.error(err.msg)
								this.resetArticleForm()
							})
					}
				})
			},
			delCover(coverID) {
				this.$confirm('是否要删除该封面?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() =>
					ajax({
						method: 'delete',
						url: `/article/cover/${coverID}`
					})
					.then(() => {
						this.$message.success('操作成功')
						this.bindCover()
					})
					.catch((err) => {
						this.$message.error(err.msg)
					})
				).catch(() => {})
			},
			deleteallfile() {
				this.$confirm('此操作将永久删除, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					ajax({
							method: 'delete',
							url: '/article/deleteallfile'
						}).then((res) => {
							this.$message.success('删除成功')
						})
						.catch((err) => {
							this.$message.error(err.msg)
						})
				}).catch(() => {

				})
			}
		},
		mounted() {
			this.bind()
		}
	}
</script>
<style lang="less" scoped>
	.el-tab-pane {
		padding-top: 30px;
	}

	.search {
		width: 250px;
		margin-right: 20px;
	}

	.el-col {
		margin-bottom: 30px;
		position: relative;
	}

	.coverItem {
		width: 25%;
		text-align: center;
		margin-bottom: 20px;
		position: relative;
	}

	.el-image {
		cursor: pointer;
		width: 180px;
		height: 140px;
		margin: 0 auto;
		border: solid 1px #ccc;
	}

	.el-image:hover {
		border: solid 1px #222;
	}

	.coverItem i {
		position: absolute;
		top: 0;
		cursor: pointer;
		font-size: 20px;
	}
</style>
