<template>
  <el-table-column :width="width"
                   align="center"
                   label="操作">
    <template slot-scope="scope">
      <slot name="slotBtn"
            v-bind:row="scope.row"></slot>
      <el-tooltip v-if="showEdit"
                  effect="dark"
                  content="修改"
                  placement="top">
        <el-button @click="openEditDialog(scope.row._id)"
                   type="primary"
                   icon="el-icon-edit"
                   :disabled="isDisable(model,'put')"
                   size="mini"></el-button>
      </el-tooltip>
      <el-tooltip v-if="showDel"
                  effect="dark"
                  content="删除"
                  placement="top">
        <el-button @click="removeByID(scope.row._id)"
                   type="danger"
                   :disabled="isDisable(model,'delete')"
                   icon="el-icon-delete"
                   size="mini"></el-button>
      </el-tooltip>
    </template>
  </el-table-column>
</template>

<script>
import isDisable from 'mixins/isDisable.js'
import ajax from 'utils/ajax'
export default {
  mixins: [isDisable],
  props: {
    width: {
      type: String,
      default: '120'
    },
    model: {
      type: String,
      required: true
    },
    showEdit: {
      type: Boolean,
      default: true
    },
    showDel: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    removeByID (id) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ajax({
          method: 'delete',
          url: `/${this.model}/${id}`
        }).then((res) => {
          this.$message.success('删除成功')
          this.$emit('removeByID', id)
        })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      }
      ).catch(() => {

      })
    },
    openEditDialog (id) {
      this.$emit('openEditDialog', id)
    }
  }
}
</script>
