<template>
  <el-container class="main-container">
    <el-header>
      <h4>双黑个人网站后台管理系统</h4>
      <h5>欢迎您：{{username}}</h5>
      <el-link type="info"
               icon="fa fa-sign-out fa-fw"
               @click="loginOut">退出</el-link>
    </el-header>
    <el-container>
      <LeftAside></LeftAside>
      <el-container>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </el-container>

</template>

<script>
// 导入左侧导航
import LeftAside from 'components/project/leftAside.vue'
import clear from 'utils/clear'
export default {
  components: {
    LeftAside
  },
  data () {
    return {
      username: window.sessionStorage.getItem('username')
    }
  },
  methods: {
    loginOut () {
      clear()
    }
  }
}
</script>

<style lang="less" scoped>
.main-container {
  height: 100%;
  .el-header {
    background-color: #373d41;
    display: flex;
    justify-content: space-between;
    h4 {
      color: #fff;
      letter-spacing: 1px;
      // flex-grow: 2;
    }
    h5 {
      color: #fff;
    }
  }
  .el-main {
    background-color: #eaedf1;
  }
}
</style>
