<template id="CreateTime">
  <el-table-column :width=width
                   label="创建时间">
    <template slot-scope="scope">
      {{scope.row.createDatetime | showTime}}
    </template>
  </el-table-column>
</template>

<script>
import basecol from 'mixins/basecol'
export default {
  props: {
    width: {
      type: String,
      default: '140'
    }
  },
  mixins: [basecol]
}
</script>
