<template>
  <div>
    <sh-bread first="系统设置"
              second="黑名单"></sh-bread>
    <el-card class="box-card">
      <el-button @click="openDialog()"
                 type="primary"
                 :disabled="isDisable('blacklist','post')"
                 size="medium">添加</el-button>
      <el-button type="danger"
                 size="medium"
                 :disabled="isDisable('blacklist','delete')"
                 @click="clear()">清空</el-button>
      <div class="tagGroup">
        <el-tag v-for="item in blacklist"
                :key="item._id"
                @close="tagClose(item._id)"
                closable>
          <span :title="item | showInfo">{{item.IP}}</span>
        </el-tag>
      </div>
    </el-card>
    <!--弹出的对话框-->
    <el-dialog title="添加黑名单"
               :visible.sync="dialogVisible"
               @close="textarea=''"
               :close-on-click-modal="false"
               width="30%">
      <el-input type="textarea"
                :rows="4"
                placeholder="请输入IP支持IP4/IP6,多个请用逗号分隔"
                v-model="textarea">
      </el-input>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="btnOK">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ajax from 'utils/ajax'
import moment from 'moment'
import isDisable from 'mixins/isDisable.js'
import { IP } from 'utils/regexp.js'
export default {
  mixins: [isDisable],
  data () {
    return {
      blacklist: [],
      textarea: '',
      dialogVisible: false
    }
  },
  created () {
    this.bindList()
  },
  filters: {
    showInfo (item) {
      return `${item.createUser} 创建于 ${moment(item.createDatetime).format('YYYY-MM-DD HH:mm')}` // 展示title内容
    },
    getType (isImportant) {
      if (isImportant) {
        return 'primary'
      } else {
        return 'info'
      }
    }
  },
  methods: {
    btnOK () {
      const ary = this.textarea.replace('，', ',').split(',')
      for (const item of ary) {
        if (!IP.test(item)) {
          this.$message.error('IP格式不正确')
          return false
        }
      }
      ajax({
        method: 'post',
        data: { IPs: this.textarea },
        url: '/blacklist'
      }).then((res) => {
        this.dialogVisible = false
        this.bindList()
      })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    openDialog () {
      this.dialogVisible = true
    },
    bindList () {
      ajax({
        method: 'get',
        url: '/blacklist'
      }).then((res) => {
        this.blacklist = res.data
      })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    tagClose (id) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ajax({
          method: 'delete',
          url: `/blacklist/${id}`
        }).then((res) => {
          this.$message.success('删除成功')
          this.bindList()
        })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      }
      ).catch(() => {

      })
    },
    clear () {
      this.$confirm('确实要清空所以黑名单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ajax({
          method: 'delete',
          url: '/blacklist'
        }).then((res) => {
          this.$message.success('删除成功')
          this.bindList()
        })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      }
      ).catch(() => {

      })
    }
  }
}
</script>
<style lang="less" scoped>
.el-input {
  width: 200px;
  margin-right: 20px;
}
.el-dialog-div {
  height: 300px;
}
.tagGroup {
  margin-top: 15px;
}
.el-tag {
  flex-grow: 1;
  margin: 3px 5px;
  cursor: pointer;
}
</style>
