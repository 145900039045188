<template>
  <div>
    <sh-bread first="网站管理"
              second="友情链接"></sh-bread>
    <el-card class="box-card">
      <el-button @click="openDialog()"
                 type="primary"
                 :disabled="isDisable('tag','post')"
                 size="medium">添加</el-button>

      <div class="tagGroup">
        <el-tag v-for="item in bindlist"
                :key="item._id"
                type="info"
                @close="tagClose(item._id)"
                closable>
          <span :title="item | showInfo">{{item.name}}</span>
        </el-tag>
      </div>
    </el-card>
    <!--弹出的对话框-->
    <el-dialog title="添加友情链接"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               @close="resetFriendLinkFrom"
               width="40%">
      <el-form label-width="80px"
               :model="friendlinkForm"
               :rules="friendlinkRule"
               ref="friendlinktRef">
        <el-form-item label="名称"
                      prop="name">
          <el-col :span="12">
            <el-input size="small"
                      v-model="friendlinkForm.name"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="链接"
                      prop="link">
          <el-col :span="16">
            <el-input size="small"
                      style="width:350px;"
                      placeholder="http(s)://xxx.xxx.xxx"
                      v-model="friendlinkForm.link"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="排序"
                      prop="sort">
          <el-col :span="12">
            <el-input size="small"
                      v-model.number="friendlinkForm.sort"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="可用"
                      prop="enable">
          <el-switch v-model="friendlinkForm.enable">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="btnOK">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ajax from 'utils/ajax'
import moment from 'moment'
import isDisable from 'mixins/isDisable.js'
import { URL } from 'utils/regexp'
export default {
  mixins: [isDisable],
  data () {
    const validateSort = (rule, value, callback) => {
      if (!Number.isInteger(value)) callback(new Error('请输入数字值'))
      else callback()
    }
    const validataUrl = (rule, value, callback) => {
      if (URL.test(value)) callback()
      else callback(new Error('URL格式不正确'))
    }
    return {
      bindlist: [], // 绑定的数据
      dialogVisible: false,
      friendlinkForm: {
        name: '',
        sort: 0,
        link: '',
        enable: true
      }, // 对话框绑定的对象
      friendlinkRule: {
        name: { required: true, message: '请输入网站名', trigger: 'blur' },
        sort: [{ validator: validateSort, trigger: 'blur' }],
        link: [{ validator: validataUrl, trigger: 'blur' }]
      }
    }
  },
  created () {
    this.bind()
  },
  filters: {
    showInfo (item) {
      return `${item.link}\n${item.createUser} 创建于 ${moment(item.createDatetime).format('YYYY-MM-DD HH:mm')}` // 展示title内容
    }
  },
  methods: {
    btnOK () {
      this.$refs.friendlinktRef.validate((valid) => {
        if (!valid) return
        const url = '/friendlink'
        const data = this.friendlinkForm
        const method = 'post'
        ajax({
          method,
          url,
          data
        })
          .then((res) => {
            this.$message.success('操作成功')
            this.bind()
            this.dialogVisible = false
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      })
    },
    resetFriendLinkFrom () {
      this.$refs.friendlinktRef.resetFields()
    },
    openDialog () {
      this.dialogVisible = true
    },
    bind () {
      ajax({
        method: 'get',
        url: '/friendlink'
      }).then((res) => {
        this.bindlist = res.data
      })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    tagClose (id) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ajax({
          method: 'delete',
          url: `/friendlink/${id}`
        }).then(() => {
          this.$message.success('删除成功')
          this.bind()
        })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      }
      ).catch(() => {

      })
    }
  }
}
</script>
<style lang="less" scoped>
.el-input {
  width: 200px;
  margin-right: 20px;
}
.el-dialog-div {
  height: 300px;
}
.tagGroup {
  margin-top: 15px;
}
.el-tag {
  flex-grow: 1;
  margin: 3px 5px;
  cursor: pointer;
}
.alert {
  width: 400px;
  margin-top: 20px;
}
</style>
