<template>
  <div>
    <sh-bread first="网站管理"
              second="评论管理"></sh-bread>
    <el-card class="box-card">
      <el-input class="search"
                placeholder="文章ID"
                v-model="query"
                size="medium"
                clearable
                suffix-icon="el-icon-search">
      </el-input>
      <el-button type="primary"
                 size="medium"
                 :disabled="isDisable('zan','get')"
                 @click="bind()">搜索</el-button>
      <sh-table :loading="loading"
                :dataList="replyList">
        <el-table-column prop="article.title"
                         label="标题">
        </el-table-column>
        <el-table-column prop="from"
                         label="发送人">
        </el-table-column>
        <el-table-column prop="to"
                         label="接收人">
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            <el-popover trigger="hover"
                        placement="top">
              {{scope.row.content}}
              <span slot="reference"
                    class="name-wrapper">
                {{ scope.row.content|showReply }}
              </span>
            </el-popover>
          </template>
        </el-table-column>
        <col-enable model="reply" />
        <col-createuser />
        <col-createtime />
        <col-modifyuser />
        <col-modifytime />
        <col-excute model="reply"
                    width="200px"
                    @openEditDialog="openDialog"
                    @removeByID="bind">
        </col-excute>
      </sh-table>
      <el-pagination background
                     layout="prev, pager, next, jumper"
                     :page-size=pagesize
                     @current-change="handleCurrentChange"
                     :total=allcount>
      </el-pagination>
    </el-card>
    <!--弹出的对话框-->
    <el-dialog title="编辑"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               width="30%"
               @close="resetReplyForm">
      <el-form label-width="80px"
               :model="replyForm"
               :rules="replyRule"
               ref="replyRef">
        <el-form-item label="名称"
                      prop="content">
          <el-col>
            <el-input size="small"
                      type="textarea"
                      :rows="5"
                      v-model="replyForm.content"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="可用"
                      prop="enable">
          <el-switch v-model="replyForm.enable">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="btnOK">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ajax from 'utils/ajax'
import isDisable from 'mixins/isDisable.js'
export default {
  data () {
    return {
      replyList: [],
      pagesize: 10,
      pagenum: 1,
      allcount: 0,
      loading: false,
      query: '',
      dialogVisible: false,
      replyForm: {
        content: '',
        enable: false
      }, // 对话框绑定的对象
      replyRule: {
        content: [
          { required: true, message: '请输入内容', trigger: 'blur' },
          { min: 3, message: '至少为2个字符', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    this.bind()
  },
  methods: {
    btnOK () {
      this.$refs.replyRef.validate((valid) => {
        if (valid) {
          delete (this.replyForm.from)
          delete (this.replyForm.to)
          delete (this.replyForm.article)
          const method = 'put'
          ajax({
            method,
            url: '/reply',
            data: this.replyForm
          })
            .then(() => {
              this.$message.success('操作成功')
              this.resetReplyForm()
              this.dialogVisible = false
              this.bind()
            })
            .catch((err) => {
              this.$message.error(err.msg)
              this.resetReplyForm()
            })
        }
      })
    },
    openDialog (id) {
      const url = `/reply/${id}`
      ajax({
        method: 'get',
        url
      }).then((res) => {
        this.replyForm = res.data
        this.dialogVisible = true
      })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    resetReplyForm () { // 重置表单
      this.$refs.replyRef.resetFields()
    },
    bind () {
      this.loading = true
      let url = `/reply/${this.pagenum}/${this.pagesize}`
      if (this.query !== '') {
        url = `/reply/${this.pagenum}/${this.pagesize}?key=${this.query}`
      }
      ajax({
        method: 'get',
        url
      }).then((res) => {
        this.loading = false
        this.replyList = res.data.list
        this.allcount = res.data.allcount
      })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    handleCurrentChange (val) {
      this.pagenum = val
      this.bind()
    }
  },
  mixins: [isDisable],
  filters: {
    showReply (reply) {
      return `${reply.substring(0, 5)}...` // 只显示前5个字符
    }
  }
}
</script>
<style lang="less" scoped>
.search {
  width: 250px;
  margin-right: 20px;
}
</style>
