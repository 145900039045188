<template>
  <div>
    <el-row :gutter="12">
      <el-col :span="8">
        <el-card shadow="always">
          <div slot="header"
               class="clearfix">
            <span>用户信息</span>
          </div>
          <i class="el-icon-user-solid"></i> ID ： {{userInfo.ID}}
          <br><br><br><br>
          <i class="el-icon-s-tools"></i> 角色 ： <el-tag v-for="item in userInfo.RoleList"
                  :key="item">{{item}}</el-tag>
          <br><br><br><br>
          <el-button size="mini"
                     type="primary"
                     @click="openPwd()">重置密码</el-button>
          <el-button size="mini"
                     type="primary"
                     @click="openAPI()">重置APIKEY</el-button>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="always">
          <div slot="header"
               class="clearfix">
            <span>路由权限</span>
          </div>
          <template>
            <el-table :data="userInfo.routerList"
                      border
                      style="width: 100%">
              <el-table-column prop="model"
                               label="路由"
                               width="120">
              </el-table-column>
              <el-table-column label="动作">
                <template slot-scope="scope">

                  <el-tag v-for="item in scope.row.methods"
                          :key="item"
                          size="medium">{{ item }}</el-tag>
                </template>
              </el-table-column>

            </el-table>
          </template>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="always">
          <div slot="header"
               class="clearfix">
            <span>菜单权限</span>
          </div>
          <el-tree ref="refTree"
                   default-expand-all
                   :data="userInfo.menuList"
                   :props="menuProps"></el-tree>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog title="重置密码"
               :visible.sync="dialogPwd"
               :close-on-click-modal="false"
               width="30%"
               @close="resetPwdForm">
      <el-form label-width="80px"
               :rules="pwdRule"
               :model="pwdForm"
               ref="pwdRef">
        <el-form-item label="密码"
                      prop="password">
          <el-col :span="20">
            <el-input size="small"
                      type="password"
                      v-model="pwdForm.password"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="确认密码"
                      prop="password2">
          <el-col :span="20">
            <el-input size="small"
                      type="password"
                      v-model="pwdForm.password2"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogPwd=false">取 消</el-button>
        <el-button type="primary"
                   @click="btnPwd()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="重置APIKEY"
               :visible.sync="dialogAPIKEY"
               :close-on-click-modal="false"
               width="30%"
               @close="resetAPIKEY">
      <el-form label-width="80px"
               ref="APIKEYRef">
        <el-alert style="margin-bottom:10px"
                  title="点击重置后会有系统生成，仅展示一次，务必保存到本地"
                  type="warning"
                  show-icon
                  :closable="false">
        </el-alert>
        <el-form-item label="APIKEY"
                      prop="password">
          <el-col :span="18">
            <el-input size="small"
                      v-model="APIKEY"
                      placeholder="由系统生成"
                      readonly></el-input>
          </el-col>
          <el-col :span="6">
            <el-tooltip effect="dark"
                        content="点击复制"
                        placement="top">
              <el-popover placement="left"
                          content="复制完成"
                          trigger="manual"
                          v-model="popoverApi">
                <i slot="reference"
                   v-clipboard:copy="APIKEY"
                   v-clipboard:success="copyApi"
                   v-clipboard:error="copyError"
                   style="margin-top:12px;margin-left:10px;"
                   class="fa fa-copy fa-lg"></i>
              </el-popover>
            </el-tooltip>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogAPIKEY=false">取 消</el-button>
        <el-button type="primary"
                   @click="btnRefreshApiKey()">重置</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ajax from 'utils/ajax'
import { decrypt } from 'utils/crypto'
import { Password } from 'utils/regexp'
export default {
  methods: {
    btnRefreshApiKey () {
      this.$confirm('是否要重置你的apikey?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() =>
        ajax({
          method: 'put',
          url: '/user/apikey',
          data: { _id: this.userInfo.ID }
        })
          .then((res) => {
            this.APIKEY = res.data
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      ).catch(() => {
      })
    },
    openAPI () {
      this.dialogAPIKEY = true
    },
    openPwd () {
      this.dialogPwd = true
    },
    btnPwd () {
      this.$refs.pwdRef.validate((valid) => {
        if (!valid) return
        const pwd = this.$md5(this.pwdForm.password)
        ajax({
          method: 'put',
          url: '/user/password',
          data: {
            _id: this.userInfo.ID,
            password: pwd
          }
        })
          .then((res) => {
            this.$message.success('操作成功')
            this.dialogPwd = false
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      })
    },
    resetPwdForm () { // 重置表单
      this.$refs.pwdRef.resetFields()
    },
    resetAPIKEY () { // 重置表单
      this.$refs.APIKEYRef.resetFields()
    },
    copyApi () {
      this.popoverApi = true
      setTimeout(() => {
        this.popoverApi = false
      }, 2000)
    },
    copyError () {
      this.$message.error('复制失败,请手动复制')
    }
  },
  data () {
    const valPwd1 = (rule, value, callback) => {
      if (Password.test(value)) { // 必须包含数字和大小写8-16位密码
        callback()
      } else {
        callback(new Error('密码至少8位且包含数字和大小字母'))
      }
    }
    const valPwd2 = (rule, value, callback) => {
      if (value === this.pwdForm.password) {
        callback()
      } else {
        callback(new Error('两次密码不同'))
      }
    }
    return {
      dialogPwd: false,
      dialogAPIKEY: false,
      APIKEY: '',
      popoverApi: false,
      pwdRule: {
        password: [{ validator: valPwd1, trigger: 'blur' }],
        password2: [{ validator: valPwd2, trigger: 'blur' }]
      },
      userInfo: {
        ID: window.sessionStorage.getItem('apiid'),
        menuList: JSON.parse(decrypt(window.sessionStorage.getItem('menuList'))), // 菜单
        routerList: JSON.parse(decrypt(window.sessionStorage.getItem('routerList'))), // 每个路由的增删改
        RoleList: JSON.parse(decrypt(window.sessionStorage.getItem('RoleList')))// 用户角色
      },
      menuProps: {
        children: 'children',
        label: 'des'
      },
      pwdForm: {
        password: '',
        password2: ''
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-tag {
  flex-grow: 1;
  margin-right: 5px;
}
</style>
