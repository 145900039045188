<template>
  <div>
    <sh-bread first="权限管理"
              second="用户管理"></sh-bread>
    <el-card class="box-card">
      <el-input class="search"
                placeholder="用户名、真实姓名、手机"
                v-model="query"
                size="medium"
                clearable
                suffix-icon="el-icon-search">
      </el-input>
      <el-button type="primary"
                 size="medium"
                 :disabled="isDisable('user','get')"
                 @click="getUserList()">搜索</el-button>
      <el-button @click="openDialog()"
                 type="primary"
                 :disabled="isDisable('user','post')"
                 size="medium">添加</el-button>
      <sh-table :loading="loading"
                :dataList="userList">
        <el-table-column prop="name"
                         label="用户名">
        </el-table-column>
        <el-table-column prop="realyname"
                         label="名字">
        </el-table-column>
        <!-- <el-table-column prop="email"
                         label="邮箱">
        </el-table-column> -->
        <el-table-column prop="mobile"
                         label="手机">
        </el-table-column>
        <el-table-column prop="department.name"
                         label="部门">
        </el-table-column>
        <col-createuser />
        <col-createtime />
        <col-modifyuser />
        <col-modifytime />
        <col-enable model="user" />
        <col-excute model="user"
                    width="200px"
                    @openEditDialog="openDialog"
                    @removeByID="getUserList">
          <template v-slot:slotBtn="slotBtn">
            <el-tooltip effect="dark"
                        content="分配角色"
                        placement="top">
              <el-button @click="openRoleDialog(slotBtn.row._id)"
                         type="warning"
                         icon="el-icon-s-custom"
                         size="mini"></el-button>
            </el-tooltip>
          </template>
        </col-excute>
      </sh-table>
      <el-pagination background
                     layout="prev, pager, next, jumper"
                     :page-size=pagesize
                     @current-change="handleCurrentChange"
                     :total=allcount>
      </el-pagination>
    </el-card>
    <!--添加对话框-->
    <el-dialog title="添加"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               width="40%"
               @close="resetUserForm">
      <el-alert style="margin:0 auto 20px auto"
                title="APIKey  将会在添加成功后展示，且仅展示这一次，务必保存到本地"
                type="warning"
                show-icon
                :closable="false">
      </el-alert>
      <el-form label-width="80px"
               :model="userInsertForm"
               :rules="userRule"
               ref="userRef">
        <el-row>
          <el-col :span="12">
            <el-form-item label="用户名"
                          prop="name">
              <el-input size="small"
                        placeholder="4-20位字母或数字"
                        v-model="userInsertForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="部门"
                          prop="department">
              <el-select size="small"
                         v-model="userInsertForm.department">
                <el-option v-for="item in departmentList"
                           :key="item.name"
                           :label="item.name"
                           :value="item._id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名"
                          prop="realyname">
              <el-input size="small"
                        v-model="userInsertForm.realyname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机"
                          prop="mobile">
              <el-input size="small"
                        v-model="userInsertForm.mobile"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="邮箱"
                          prop="email">
              <el-input size="small"
                        v-model="userInsertForm.email"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="可用">
              <el-switch v-model="userInsertForm.enable">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="18">
            <el-form-item label="初始密码"
                          prop="password">

              <el-input size="small"
                        readonly
                        placeholder="不得手动设置，点击右侧按钮生成"
                        v-model="userInsertForm.password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-tooltip effect="dark"
                        content="刷新密码"
                        placement="top">
              <i @click="btnRefreshPWD"
                 style="margin-top:12px;margin-left:10px;"
                 class="fa fa-pencil-square-o fa-lg"></i>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="18">
            <el-form-item label="APIKey"
                          prop="apikey">
              <el-input size="small"
                        readonly
                        placeholder="添加成功后系统生成"
                        :value="apikey"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-tooltip effect="dark"
                        content="点击复制"
                        placement="top">
              <el-popover placement="left"
                          content="复制完成"
                          trigger="manual"
                          v-model="popoverVisible">
                <i slot="reference"
                   v-clipboard:copy="apikey"
                   v-clipboard:success="copySuccess"
                   v-clipboard:error="copyError"
                   style="margin-top:12px;margin-left:10px;"
                   class="fa fa-copy fa-lg"></i>
              </el-popover>
            </el-tooltip>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="btnOK">确 定</el-button>
      </span>
    </el-dialog>
    <!--修改对话框-->
    <el-dialog :visible.sync="updateDialogVisible"
               :close-on-click-modal="false"
               width="40%"
               @close="resetUpdateUserForm">
      <el-tabs v-model="activeName"
               type="card">
        <el-tab-pane label="修改资料"
                     name="first">
          <el-form label-width="80px"
                   :model="userUpdateForm"
                   :rules="userUpdateRule"
                   ref="userEditRef">
            <el-row>
              <el-col :span="12">
                <el-form-item label="用户名"
                              prop="name">
                  <el-input size="small"
                            readonly
                            placeholder="4-20位字母或数字"
                            v-model="userUpdateForm.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="部门"
                              prop="department">
                  <el-select size="small"
                             v-model="userUpdateForm.department">
                    <el-option v-for="item in departmentList"
                               :key="item.name"
                               :label="item.name"
                               :value="item._id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="姓名"
                              prop="realyname">
                  <el-input size="small"
                            v-model="userUpdateForm.realyname"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机"
                              prop="mobile">
                  <el-input size="small"
                            v-model="userUpdateForm.mobile"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="邮箱"
                              prop="email">
                  <el-input size="small"
                            v-model="userUpdateForm.email"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="可用">
                  <el-switch v-model="userUpdateForm.enable">
                  </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item class="btns">
                  <el-button @click="updateDialogVisible=false">取消</el-button>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item class="btns">
                  <el-button type="primary"
                             @click="btnUpdateOK">确定</el-button>

                </el-form-item>
              </el-col>
              <el-col :span="12"> </el-col>
            </el-row>

          </el-form>
        </el-tab-pane>
        <el-tab-pane label="重置ApiKey"
                     name="second">
          <el-row>
            <el-col :span="18">
              <el-alert style="margin-bottom:10px"
                        :title="apikeytitle"
                        type="warning"
                        show-icon
                        :closable="false">
              </el-alert>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-input width="120"
                        placeholder="由系统生成,仅展示一次,务必保存到本地"
                        size="small"
                        :value='apikey'
                        readonly>
              </el-input>
            </el-col>
            <el-col :span="6">
              <el-button @click="btnRefreshApiKey()"
                         type="primary"
                         size="small">点击重置</el-button>
              <el-tooltip effect="dark"
                          content="点击复制"
                          placement="top">
                <el-popover placement="left"
                            content="复制完成"
                            trigger="manual"
                            v-model="popoverApi">
                  <i slot="reference"
                     v-clipboard:copy="apikey"
                     v-clipboard:success="copyApi"
                     v-clipboard:error="copyError"
                     style="margin-top:12px;margin-left:10px;"
                     class="fa fa-copy fa-lg"></i>
                </el-popover>
              </el-tooltip>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="重置密码"
                     name="three">
          <el-row>
            <el-col :span="18">
              <el-alert style="margin-bottom:10px"
                        :title="passwordtitle"
                        type="warning"
                        show-icon
                        :closable="false">
              </el-alert>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-input width="120"
                        placeholder="点击重置后系统自动生成并保存"
                        size="small"
                        :value='password'
                        readonly>
              </el-input>
            </el-col>
            <el-col :span="6">
              <el-button @click="btnRefreshPassword()"
                         type="primary"
                         size="small">点击重置</el-button>
              <el-tooltip effect="dark"
                          content="点击复制"
                          placement="top">
                <el-popover placement="left"
                            content="复制完成"
                            trigger="manual"
                            v-model="popoverPwd">
                  <i slot="reference"
                     v-clipboard:copy="password"
                     v-clipboard:success="copyPwd"
                     v-clipboard:error="copyError"
                     style="margin-top:12px;margin-left:10px;"
                     class="fa fa-copy fa-lg"></i>
                </el-popover>
              </el-tooltip>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!--分配角色对话框-->
    <el-dialog title="分配路由"
               :visible.sync="roleDialogVisible"
               :close-on-click-modal="false"
               width="300px"
               @close="resetRoleFrom">

      <el-select multiple
                 v-model="userUpdateForm.roles"
                 placeholder="请选择">
        <el-option v-for="item in roleList"
                   :key="item._id"
                   :label="item.name"
                   :value="item._id">
        </el-option>
      </el-select>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="roleDialogVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="btnRouter">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ajax from 'utils/ajax'
import random from 'string-random'
import isDisable from 'mixins/isDisable.js'
import { UserName, Password, EmptyMobile, EmptyEmail } from 'utils/regexp'
export default {
  mixins: [isDisable],
  data () {
    const validatePass = (rule, value, callback) => {
      if (Password.test(value)) { // 必须包含数字和大小写8-16位密码
        callback()
      } else {
        callback(new Error('不符合规范'))
      }
    }
    const validateName = (rule, value, callback) => {
      if (/^\d+$/.test(value)) callback(new Error('不能全为数字')) // 不能纯数字
      if (UserName.test(value)) {
        callback()
      } else {
        callback(new Error('不符合规范'))
      }
    }
    const validateMobile = (rule, value, callback) => {
      if (EmptyMobile.test(value)) {
        callback()
      } else {
        callback(new Error('不符合规范'))
      }
    }
    const validateEmail = (rule, value, callback) => {
      if (EmptyEmail.test(value)) {
        callback()
      } else {
        callback(new Error('不符合规范'))
      }
    }
    const validateDepartment = (rule, value, callback) => {
      if (this.userInsertForm.department !== '') {
        callback()
      } else {
        callback(new Error('请选择部门'))
      }
    }
    const validateUpdateDepartment = (rule, value, callback) => {
      if (this.userUpdateForm.department !== '') {
        callback()
      } else {
        callback(new Error('请选择部门'))
      }
    }
    return {
      userList: [],
      pagesize: 10,
      pagenum: 1,
      allcount: 0,
      loading: false,
      dialogVisible: false,
      roleDialogVisible: false, // 分配角色弹出框
      updateDialogVisible: false, // 修改的对话框
      popoverVisible: false, // 复制之后的弹出框
      departmentList: [], // 所有可用部门列表
      roleList: [], // 可用角色列表
      apikey: '',
      password: '',
      query: '', // 搜索的参数
      activeName: 'first',
      popoverApi: false,
      popoverPwd: false,
      userInsertForm: {
        name: '',
        password: '',
        realyname: '',
        email: '',
        mobile: '',
        enable: true,
        department: ''
      },
      userUpdateForm: {
        _id: '',
        name: '',
        realyname: '',
        email: '',
        mobile: '',
        enable: true,
        department: '',
        rules: []
      },
      userRule: {
        name: [{ required: true, validator: validateName, trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
        mobile: [{ validator: validateMobile, trigger: 'blur' }],
        email: [{ validator: validateEmail, trigger: 'blur' }],
        department: [{ validator: validateDepartment }],
        realyname: { required: true, message: '请输入姓名', trigger: 'blur' }
      },
      userUpdateRule: {
        mobile: [{ validator: validateMobile, trigger: 'blur' }],
        email: [{ validator: validateEmail, trigger: 'blur' }],
        department: [{ validator: validateUpdateDepartment }],
        realyname: { required: true, message: '请输入姓名', trigger: 'blur' }
      }
    }
  },
  methods: {
    openDialog (id) {
      ajax({ // 获得部门列表
        method: 'get',
        url: '/department/enable'
      }).then((res) => {
        this.departmentList = res.data
        if (id) { // 如果是编辑
          this.updateDialogVisible = true
          ajax({ // 获得用户信息
            method: 'get',
            url: `/user/${id}`
          }).then((res) => {
            this.userUpdateForm = res.data
          })
            .catch((err) => {
              this.$message.error(err)
            })
        } else {
          this.dialogVisible = true
        }
      })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    btnOK () {
      this.$refs.userRef.validate((valid) => {
        if (!valid) return
        const url = '/user'
        const data = this.userInsertForm
        data.apikey = ''
        data.password = this.$md5(data.password)
        const method = 'post'
        ajax({
          method,
          url,
          data
        })
          .then((res) => {
            const { apikey } = res.data
            this.$message.success('操作成功')
            this.resetUserForm()
            this.apikey = apikey
          })
          .catch((err) => {
            console.log(err)
            this.resetUserForm()
            this.$message.error(err.msg)
          })
      })
    },
    btnUpdateOK () {
      this.$refs.userEditRef.validate((valid) => {
        if (!valid) return
        delete (this.userUpdateForm.name)
        delete (this.userUpdateForm.roles)
        const url = '/user'
        const data = this.userUpdateForm
        const method = 'put'
        ajax({
          method,
          url,
          data
        })
          .then((res) => {
            this.$message.success('操作成功')
            this.resetUpdateUserForm()
            this.getUserList()
            this.updateDialogVisible = false
          })
          .catch((err) => {
            this.resetUpdateUserForm()
            this.$message.error(err.msg)
          })
      })
    },
    btnRefreshApiKey () {
      this.$confirm('是否要重置该用户的apikey?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() =>
        ajax({
          method: 'put',
          url: '/user/apikey',
          data: { _id: this.userUpdateForm._id }
        })
          .then((res) => {
            this.apikey = res.data
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      ).catch(() => {
      })
    },
    getUserList () {
      this.loading = true
      let url = `/user/${this.pagenum}/${this.pagesize}`
      if (this.query !== '') {
        url = `/user/${this.pagenum}/${this.pagesize}?key=${this.query}`
      }
      ajax({
        method: 'get',
        url

      }).then((res) => {
        this.loading = false
        this.userList = res.data.list
        this.allcount = res.data.allcount
      })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    handleCurrentChange (val) {
      this.pagenum = val
      this.getUserList()
    },
    resetUserForm () { // 重置表单
      this.$refs.userRef.resetFields()
      this.apikey = ''
    },
    resetUpdateUserForm () {
      this.$refs.userEditRef.resetFields()
      this.apikey = ''
      this.password = ''
    },
    copySuccess () { // 复制成功后给出弹出框提示
      this.popoverVisible = true
      setTimeout(() => {
        this.popoverVisible = false
      }, 2000)
    },
    copyApi () {
      this.popoverApi = true
      setTimeout(() => {
        this.popoverApi = false
      }, 2000)
    },
    copyPwd () {
      this.popoverPwd = true
      setTimeout(() => {
        this.popoverPwd = false
      }, 2000)
    },
    copyError () {
      this.$message.error('复制失败,请手动复制')
    },
    btnRefreshPWD () {
      this.userInsertForm.password = this.createPWD()
    },
    createPWD () {
      // console.log(Password.test('246810ab'))
      while (true) {
        const pwd = random(12)
        if (Password.test(pwd)) {
          return pwd
        }
      }
    },
    btnRefreshPassword () {
      this.$confirm('是否要重置该用户的密码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const pwd = this.createPWD()
        const password = this.$md5(pwd)
        ajax({
          method: 'put',
          url: '/user/password',
          data: {
            _id: this.userUpdateForm._id,
            password
          }
        })
          .then((res) => {
            this.password = pwd
            this.$message.success('操作成功')
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      }
      ).catch(() => {
      })
    },
    resetRoleFrom () {
      this.roleList = []
    },
    openRoleDialog (id) {
      this.roleDialogVisible = true
      ajax({
        method: 'get',
        url: '/role/enable'
      })
        .then((res) => {
          this.roleList = res.data
          ajax({ // 获得用户信息
            method: 'get',
            url: `/user/${id}`
          }).then((res) => {
            this.userUpdateForm = res.data
          })
            .catch((err) => {
              this.$message.error(err)
            })
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    btnRouter () {
      ajax({
        method: 'put',
        url: '/user/roles',
        data: { _id: this.userUpdateForm._id, roles: JSON.stringify(this.userUpdateForm.roles) }
      }).then((res) => {
        this.$message.success('操作成功')
        this.userUpdateForm = {}
        this.roleDialogVisible = false
      })
        .catch((err) => {
          this.$message.error(err)
        })
    }
  },
  created () {
    this.getUserList()
  },
  computed: {
    apikeytitle () {
      return `此操作将重置 ${this.userUpdateForm.name} 的apikey`
    },
    passwordtitle () {
      return `此操作将重置 ${this.userUpdateForm.name} 的密码`
    }
  }
}
</script>
<style lang="less" scoped>
.el-tab-pane {
  padding-top: 30px;
}
.search {
  width: 250px;
  margin-right: 20px;
}
</style>
