<template>
  <div>
    <sh-bread first="文章管理"
              second="文章标签"></sh-bread>
    <el-card class="box-card">
      <el-input placeholder="输入首字母"
                v-model="PY"
                @input="inputPY"
                size="medium"
                :disabled="isDisable('tag','get')"
                clearable
                @clear="this.PY=''"
                class="serach"
                suffix-icon="el-icon-search">
      </el-input>
      <el-button @click="openDialog()"
                 type="primary"
                 :disabled="isDisable('tag','post')"
                 size="medium">添加</el-button>
      <el-alert class="alert" title="单击标签将会改变是否为热门标签，蓝色为热门标签"
                type="warning">
      </el-alert>
      <div class="tagGroup">
        <el-tag v-for="item in bindlist"
                :key="item._id"
                :type="item.IsHot | getType"
                @close="tagClose(item._id)"
                @click="clickTag(item._id,item.IsHot)"
                closable>
          <span :title="item | showInfo">{{item.name}}</span>
        </el-tag>
      </div>
    </el-card>
    <!--弹出的对话框-->
    <el-dialog title="添加标签"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               @close="textarea=''"
               width="30%">
      <el-input type="textarea"
                :rows="4"
                placeholder="多个请用逗号分隔"
                v-model="textarea">
      </el-input>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="btnOK">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ajax from 'utils/ajax'
import moment from 'moment'
import isDisable from 'mixins/isDisable.js'
export default {
  mixins: [isDisable],
  data () {
    return {
      taglist: [], // 服务器获取的数据
      bindlist: [], // 本地过滤后的数据
      textarea: '',
      PY: '',
      dialogVisible: false
    }
  },
  created () {
    this.bind()
  },
  filters: {
    showInfo (item) {
      return `${item.createUser} 创建于 ${moment(item.createDatetime).format('YYYY-MM-DD HH:mm')}` // 展示title内容
    },
    getType (isHot) {
      if (isHot) {
        return 'primary'
      } else {
        return 'info'
      }
    }
  },
  methods: {
    inputPY () {
      this.PY = this.PY.replace(/[\W]/g, '') // 限制只能输入英文和数字
      this.PY = this.PY.toUpperCase()
      const result = this.taglist.filter((n) => {
        if (n.PY.indexOf(this.PY) > -1) {
          return n
        }
      })
      this.bindlist = result
    },
    btnOK () {
      ajax({
        method: 'post',
        data: { tags: this.textarea },
        url: '/tag'
      }).then((res) => {
        this.dialogVisible = false
        this.bind()
      })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    openDialog () {
      this.dialogVisible = true
    },
    bind () {
      ajax({
        method: 'get',
        url: '/tag'
      }).then((res) => {
        this.taglist = res.data
        this.bindlist = res.data
      })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    tagClose (id) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ajax({
          method: 'delete',
          url: `/tag/${id}`
        }).then((res) => {
          this.$message.success('删除成功')
          this.bind()
        })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      }
      ).catch(() => {

      })
    },
    clickTag (id, isHot) {
      ajax({
        method: 'put',
        url: '/tag',
        data: { _id: id, IsHot: !isHot }
      }).then(() => {
        this.bind()
      })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    }
  }
}
</script>
<style lang="less" scoped>
.el-input {
  width: 200px;
  margin-right: 20px;
}
.el-dialog-div {
  height: 300px;
}
.tagGroup {
  margin-top: 15px;
}
.el-tag {
  flex-grow: 1;
  margin: 3px 5px;
  cursor: pointer;
}
.alert{
  width:400px;
  margin-top:20px;
}
</style>
