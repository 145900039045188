<template>
  <div class="login">
    <div class="login_box" @keyup.enter="login">
      <el-form :model="loginForm"
               :rules="loginRules"
               ref="loginFormRef">
        <!--两种方式加icon前面是pfefix，后面是suffix-->
        <el-form-item prop="name">
          <el-input v-model="loginForm.name"
                    maxlength="12"
                    show-word-limit
                    placeholder="请输入用户名">
            <i slot="prefix"
               class="fa fa-user fa-fw"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="loginForm.password"
                    clearable
                    type="password"
                    placeholder="请输入密码"
                    show-password
                    prefix-icon="fa fa-key fa-fw"></el-input>
        </el-form-item>
        <CheckCode ref="checkRef"
                   :rules=loginRules>
        </CheckCode>
        <el-form-item class="btns">
          <el-button type="primary"
                     @click="login">登录</el-button>
          <el-button @click="resetLoginFrom">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import ajax from 'utils/ajax'
import { encrypt } from 'utils/crypto'
import CheckCode from 'components/common/checkCode.vue'
import { UserName, Password } from 'utils/regexp'
export default {
  components: {
    CheckCode
  },
  data () {
    const validatePass = (rule, value, callback) => {
      if (Password.test(value)) { // 必须包含数字和大小写8-16位密码
        callback()
      } else {
        callback(new Error('密码不符合规范'))
      }
    }
    const validateName = (rule, value, callback) => {
      if (/^\d+$/.test(value)) callback(new Error('用户名不能全为数字')) // 不能纯数字
      if (UserName.test(value)) { // 必须包含数字和大小写8-16位密码
        callback()
      } else {
        callback(new Error('用户名不符合规范'))
      }
    }
    return {
      loginForm: {
        name: '',
        password: ''
      },
      loginRules: {
        name: [{ validator: validateName, trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }]
      }
    }
  },
  methods: {
    resetLoginFrom () { // 重置表单
      this.$refs.loginFormRef.resetFields()
    },
    login () {
      this.$refs.loginFormRef.validate((valid) => {
        if (!valid) return
        ajax({
          method: 'post',
          url: '/user/login',
          data: {
            name: this.loginForm.name,
            password: this.$md5(this.loginForm.password)
          }
        })
          .then((res) => {
            window.sessionStorage.setItem('apiid', res.data._id) // 只保留session
            window.sessionStorage.setItem('apikey', encrypt(res.data.apikey))
            window.sessionStorage.setItem('username', this.loginForm.name)
            window.sessionStorage.setItem('menuList', encrypt(res.data.menuList)) // 菜单
            window.sessionStorage.setItem('routerList', encrypt(res.data.routerList))// 每个路由的增删改
            window.sessionStorage.setItem('UIrouter', encrypt(res.data.UIrouter))// 是否有路由的入口
            window.sessionStorage.setItem('RoleList', encrypt(res.data.rolenameList))// 用户角色
            // this.$store.commit({ //由于vuex是保存在内存中，页面刷新后 就不存在了。所以弃用 还是用本地储存吧
            //   type: 'setmenu',
            //   menuList: res.data.menuList,
            //   routerList: res.data.routerList,
            //   UIrouter: res.data.UIrouter
            // })
            this.$router.push('/welcome')
          })
          .catch((err) => {
            this.$refs.checkRef.reset() // 重置验证码
            this.$refs.loginFormRef.resetFields()
            // console.log(err.msg)
            this.$message.error(err.msg)
          })
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
.login {
  height: 100%;
  background-color: #2b486b;
}
.login_box {
  background-color: #fff;
  position: absolute;
  width: 400px;
  height: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  form {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
  }
  .btns {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
