<template>
  <el-table :data="dataList"
            :loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            border
            stripe>
    <slot></slot>
  </el-table>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Array
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
