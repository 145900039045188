import CryptoJS from 'crypto-js'
const keyOne = 'shuanghei1233210' // 密钥必须是8的整数倍
export const encrypt = (word) => {
  const key = CryptoJS.enc.Hex.parse(keyOne)
  let enc = ''
  if (typeof word === 'string') {
    enc = CryptoJS.AES.encrypt(word, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
  } else if (typeof word === 'object') {
    const data = JSON.stringify(word)
    enc = CryptoJS.AES.encrypt(data, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
  }
  const encResult = enc.ciphertext.toString()
  return encResult
}
/**
 * 解密
 */
export const decrypt = (word) => {
  const key = CryptoJS.enc.Hex.parse(keyOne)
  const dec = CryptoJS.AES.decrypt(CryptoJS.format.Hex.parse(word), key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  const decData = CryptoJS.enc.Utf8.stringify(dec)
  return decData
}
