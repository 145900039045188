<template>
  <el-form-item prop="checkrule">
    <el-input v-model="secode"
              @input="changeTxt"
              maxlength="4"
              placeholder="请输入验证码"
              prefix-icon="fa fa-check fa-fw"></el-input>
    <span class="checkCode"
          @click="createCode">{{checkcode}}</span>
  </el-form-item>
</template>

<script>
export default {
  data () {
    const validateCheckCode = (rule, value, callback) => {
      if (this.checkcode === this.secode.toUpperCase()) {
        callback()
      } else {
        this.secode = ''
        this.createCode()
        callback(new Error('验证码错误'))
      }
    }
    return {
      secode: '',
      checkcode: '',
      drules: this.rules, // 父组件传来的rules
      checkrule: { validator: validateCheckCode, trigger: 'blur' }
    }
  },
  methods: {
    reset () { // 清空
      this.secode = ''
      this.createCode()
    },
    changeTxt () {
      this.secode = this.secode.toUpperCase()
    },
    // 生成验证码
    createCode () {
      let code = ''
      const codeLength = 4 // 验证码的长度
      const random = [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z'
      ] // 随机数
      for (let i = 0; i < codeLength; i++) {
        // 循环操作
        const index = Math.floor(Math.random() * 36) // 取得随机数的索引（0~35）
        code += random[index] // 根据索引取得随机数加到code上
      }
      this.checkcode = code // 把code值赋给验证码
    }
  },
  props: {
    rules: {
      type: Object
    }
  },
  mounted () {
    this.createCode()
    this.drules.checkrule = this.checkrule // 与父组件传来的rules双向绑定,当更改此组件的rules时，父组件也会更改
  }
}
</script>

<style lang="less" scoped>
.checkCode {
  position: absolute;
  right: 5px;
  top: 0;
  color: #282828;
  cursor: pointer;
}
.input el-input__inner {
  transform: uppercase;
  color: red;
}
</style>
