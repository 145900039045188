<template>
  <div>
    <sh-bread first="系统设置"
              second="敏感词设置"></sh-bread>
    <el-card class="box-card">
      <el-input placeholder="输入首字母"
                v-model="PY"
                @input="inputPY"
                size="medium"
                :disabled="isDisable('sensitiveword','get')"
                clearable
                @clear="refushWord()"
                class="serach"
                suffix-icon="el-icon-search">
      </el-input>
      <el-button @click="openDialog()"
                 type="primary"
                 :disabled="isDisable('sensitiveword','post')"
                 size="medium">添加</el-button>
      <el-button type="danger"
                 size="medium"
                 :disabled="isDisable('sensitiveword','delete')"
                 @click="clear()">清空</el-button>
      <div class="tagGroup">
        <el-tag v-for="item in words"
                :key="item._id"
                :type="item.isImportant | getType"
                @close="tagClose(item._id)"
                closable>
          <span :title="item | showInfo">{{item.word}}</span>
        </el-tag>
      </div>
    </el-card>
    <!--弹出的对话框-->
    <el-dialog :visible.sync="dialogVisible"
               custom-class="el-dialog"
               :close-on-click-modal="false"
               @close="resetWordForm"
               width="40%">
      <div class="el-dialog-div">
        <el-tabs v-model="activeName"
                 type="card">
          <el-tab-pane label="单独添加"
                       name="first">
            <el-form label-width="80px"
                     :model="wordFrom"
                     :rules="wordRule"
                     ref="wordRef">
              <el-form-item label="名称"
                            prop="word">
                <el-input size="small"
                          v-model="wordFrom.word"></el-input>
              </el-form-item>
              <el-form-item label="重要"
                            prop="enable">
                <el-switch v-model="wordFrom.isImportant">
                </el-switch>
              </el-form-item>
              <el-form-item class="btns">
                <el-button type="primary"
                           @click="btnAdd">确认</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="批量上传"
                       name="second">
            <el-upload name="words"
                       ref="upload"
                       action="/sensitiveword/upload"
                       :before-upload="beforeAvatarUpload"
                       :auto-upload="true">
              <el-button slot="trigger"
                         size="small"
                         type="primary"
                         style="margin-left:20px;">选取文件</el-button>
              <el-select size="small"
                         style="width:120px;margin-left:40px;"
                         v-model="selectvalue"
                         placeholder="请选择级别">
                <el-option v-for="item in options"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
              <div slot="tip"
                   style="margin-top:30px;">必须utf8编码的txt文件，逗号或者换行来分隔，不得超过2M</div>
            </el-upload>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import ajax from 'utils/ajax'
import moment from 'moment'
import isDisable from 'mixins/isDisable.js'
export default {
  mixins: [isDisable],
  data () {
    return {
      PY: '',
      words: [],
      dialogVisible: false,
      activeName: 'first',
      wordFrom: {
        word: '',
        isImportant: false
      },
      wordRule: {
        word: { required: true, message: '请输入单词', trigger: 'blur' }
      },
      options: [{
        val: 'false',
        label: '普通'
      }, {
        value: 'true',
        label: '重要'
      }],
      selectvalue: '' // 下拉框绑定的值
    }
  },
  filters: {
    showInfo (item) {
      return `${item.createUser} 创建于 ${moment(item.createDatetime).format('YYYY-MM-DD HH:mm')}` // 展示title内容
    },
    getType (isImportant) {
      if (isImportant) {
        return 'primary'
      } else {
        return 'info'
      }
    }
  },
  created () {
    this.refushWord = this.debounce(this.refushWord, 600) // 防抖动处理
  },
  methods: {
    openDialog () {
      this.dialogVisible = true
    },
    btnAdd () {
      this.$refs.wordRef.validate((valid) => {
        if (!valid) return
        ajax({
          method: 'post',
          url: '/sensitiveword',
          data: this.wordFrom
        })
          .then((res) => {
            this.$message.success('添加成功')
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      })
    },
    beforeAvatarUpload (file) { // 上传前事件
      if (this.selectvalue === '') {
        this.$message.error('请先选择级别')
        return false
      }
      if (file.type === 'text/plain' && file.size / 1024 / 1024 < 2) {
        this.submitUpload(file)
        return true
      }

      this.$message.error('必须是txt文件且不得超过2M')
      return false
    },
    submitUpload (file) {
      const formData = new FormData()
      formData.append('words', file) // key必须是words，否则上传不成功
      formData.append('isImportant', this.selectvalue)
      ajax({
        method: 'post',
        url: '/sensitiveword/upload',
        data: formData,
        transformRequest: null // 必须加上这句把默认配置里的取消掉
      }
      )
        .then((res) => {
          const { successLength, repeatLength, otherLength } = res.data
          this.dialogVisible = false
          this.selectvalue = ''
          this.$message.success(`成功${successLength}条，重复${repeatLength}条，失败${otherLength}条`)
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    inputPY () {
      this.PY = this.PY.replace(/[\W]/g, '') // 限制只能输入英文和数字
      this.PY = this.PY.toUpperCase()
      this.refushWord()
    },
    refushWord () { // 刷新列表
      if (this.PY !== '') {
        ajax({
          method: 'get',
          url: `/sensitiveword/${this.PY}`
        })
          .then((res) => {
            this.words = res.data
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      } else {
        this.words = []
      }
    },
    debounce (fn, delay = 200) { // 防抖动处理
      let timer = null
      return () => {
        const th = this
        const args = arguments
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          timer = null
          fn.apply(th, args)
        }, delay)
      }
    },
    clear () { // 清空
      this.$confirm('是否要删除所有敏感词?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() =>
        ajax({
          method: 'delete',
          url: '/sensitiveword/'
        })
          .then((res) => {
            this.words = []
            this.$message.success(`成功删除${res.data.n}条数据`)
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      ).catch(() => {
      })
    },
    tagClose (id) { // 删除词条
      ajax({
        method: 'delete',
        url: `/sensitiveword/${id}`
      })
        .then((res) => {
          this.words = this.words.filter(n => { // 这里是界面中删除
            return n._id !== id // 过滤
          })
          this.$message.success('成功删除 1 条数据')
        }).catch((err) => {
          this.$message.error(err.msg)
        })
    },
    resetWordForm () {
      this.$refs.wordRef.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
.el-card {
  min-height: 400px;
}
.el-tab-pane {
  padding-top: 30px;
}
.el-input {
  width: 200px;
  margin-right: 20px;
}
.el-dialog-div {
  height: 300px;
}
.tagGroup {
  margin-top: 15px;
}
.el-tag {
  flex-grow: 1;
  margin: 3px 5px;
  cursor: pointer;
}
</style>
