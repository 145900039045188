<template>
  <div>
    <sh-bread first="权限管理"
              second="角色管理"></sh-bread>
    <el-card class="box-card">
      <el-button @click="openDialog()"
                 type="primary"
                 :disabled="isDisable('role','post')"
                 size="medium">添加</el-button>
      <sh-table :loading="loading"
                :dataList="roleList">
        <el-table-column type="index"
                         label="#">
        </el-table-column>
        <el-table-column prop="name"
                         label="名字">
        </el-table-column>
        <el-table-column prop="description"
                         label="描述">
        </el-table-column>
        <col-sort />
        <col-createuser />
        <col-createtime />
        <col-modifyuser />
        <col-modifytime />
        <col-enable model="role" />
        <col-excute model="role"
                    width="260"
                    @openEditDialog="openDialog"
                    @removeByID="getRoleList">
          <template v-slot:slotBtn="slotBtn">
            <el-tooltip effect="dark"
                        content="分配路由"
                        placement="top">
              <el-button @click="openRouterDialog(slotBtn.row._id)"
                         type="warning"
                         icon="el-icon-set-up"
                         size="mini"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark"
                        content="分配菜单"
                        placement="top">
              <el-button @click="openMenuDialog(slotBtn.row._id)"
                         type="primary"
                         icon="el-icon-menu"
                         size="mini"></el-button>
            </el-tooltip>
          </template>
        </col-excute>
      </sh-table>
    </el-card>
    <!--弹出的对话框-->
    <el-dialog :title="dialogTitle"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               width="30%"
               @close="resetRoleFrom">
      <el-form label-width="80px"
               :model="roleForm"
               :rules="roleRule"
               ref="roleRef">
        <el-form-item label="名称"
                      prop="name">
          <el-col :span="12">
            <el-input size="small"
                      v-model="roleForm.name"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="描述"
                      prop="description">
          <el-col :span="18">
            <el-input size="small"
                      :rows="2"
                      type="textarea"
                      v-model="roleForm.description"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="排序"
                      prop="sort">
          <el-col :span="4">
            <el-input size="small"
                      v-model.number="roleForm.sort"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="可用"
                      prop="enable">
          <el-switch v-model="roleForm.enable">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="btnOK">确 定</el-button>
      </span>
    </el-dialog>

    <!--分配路由对话框-->
    <el-dialog title="分配路由"
               :visible.sync="routerDialogVisible"
               :close-on-click-modal="false"
               width="550px"
               @close="resetRouterFrom">
      <el-col :span="8">
        <el-input style="margin-bottom:20px"
                  v-model.trim="routerSearch"
                  size="small"
                  placeholder="搜索模块或者描述" />
      </el-col>
      <el-alert title="带*符号的，代表此模块需要进行IP验证"
                type="warning"
                :closable="false">
      </el-alert>
      <el-table :data="systemRouterList.filter(data => !routerSearch || data.model.toLowerCase().includes(routerSearch.toLowerCase())|| data.des.toLowerCase().includes(routerSearch.toLowerCase()))"
                lazy
                ref="refRouter"
                stripe
                border>
        <el-table-column width="120"
                         prop="model"
                         label="模块">
          <template slot-scope="scope">
            {{scope.row.model}}{{scope.row.onlyHost===true?" *":""}}
          </template>
        </el-table-column>
        <el-table-column prop="des"
                         label="描述"
                         width="80px">
        </el-table-column>
        <el-table-column align="right"
                         label="功能">
          <template slot-scope="scope">
            <el-checkbox v-for="item in scope.row.methods"
                         @change="checked=>changeRouter(checked,scope.row.model,item)"
                         :checked="isChecked(scope.row.model,item)"
                         :key="item+Math.random()">{{item|getMethodsName}}</el-checkbox>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="routerDialogVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="btnRouter">确 定</el-button>
      </span>
    </el-dialog>

    <!--分配菜单对话框-->
    <el-dialog title="分配菜单"
               :visible.sync="menuDialogVisible"
               :close-on-click-modal="false"
               width="300px"
               @close="resetMenuForm">
      <el-tree ref="refTree"
               default-expand-all
               :data="systemMenuList"
               :default-checked-keys="defMenu"
               :props="menuProps"
               show-checkbox
               node-key="router"></el-tree>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="menuDialogVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="btnMenu">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ajax from 'utils/ajax'
import isDisable from 'mixins/isDisable.js'
export default {
  mixins: [isDisable],
  data () {
    const validateSort = (rule, value, callback) => {
      if (!Number.isInteger(value)) callback(new Error('请输入数字值'))
      else callback()
    }
    return {
      roleList: [],
      dialogVisible: false, // 对话框是否展示
      dialogTitle: '', // 对话框的标题
      routerDialogVisible: false, // 分配路由对话框
      menuDialogVisible: false, // 分配菜单对话框
      systemRouterList: [], // 系统所有的路由
      roleRouterList: [], // 当前角色的路由
      routerSearch: '', // 路由搜索
      systemMenuList: [], // 系统所有的菜单
      defMenu: [], // 选中的菜单
      checked: false,
      currentRoleId: '', // 当前编辑的角色id
      roleForm: {
        name: '',
        sort: 0,
        description: '',
        enable: true
      }, // 对话框绑定的对象
      roleRule: {
        name: { required: true, message: '请输入部门名', trigger: 'blur' },
        sort: [{ validator: validateSort, trigger: 'blur' }],
        description: { required: true, message: '请输入描述信息', trigger: 'blur' }
      },
      menuProps: {
        children: 'children',
        label: 'des'
      }
    }
  },
  created () {
    this.getRoleList()
  },
  methods: {
    resetRoleFrom () { // 重置表单
      this.$refs.roleRef.resetFields()
    },
    resetRouterFrom () {
      this.routerSearch = ''
      this.systemRouterList = []
      this.roleRouterList = []
      this.currentRoleId = ''
    },
    getRoleList () {
      this.loading = true
      ajax({
        method: 'get',
        url: '/role'
      }).then((res) => {
        this.loading = false
        this.roleList = res.data
      })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    openDialog (id) { // 打开对话框
      if (id) { // 编辑状态
        this.dialogTitle = '修改'
        ajax({
          method: 'get',
          url: `/role/${id}`
        })
          .then((res) => {
            delete (res.data.routerList) // 删除此属性
            this.roleForm = res.data
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      } else {
        delete (this.roleForm._id) // 添加时候不能有_id
        this.dialogTitle = '添加' // 添加状态
      }
      this.dialogVisible = true
    },
    openRouterDialog (id) {
      this.currentRoleId = id
      this.routerDialogVisible = true
      ajax(
        {
          method: 'get',
          url: `/role/${id}`
        }
      ).then((res) => {
        this.roleRouterList = res.data.routerList
        ajax({
          method: 'get',
          url: '/role/systemrouter'
        })
          .then((res) => {
            this.systemRouterList = res.data
          })
      })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    btnOK () {
      this.$refs.roleRef.validate((valid) => {
        if (!valid) return
        const url = '/role'
        const data = this.roleForm
        let method = 'post'
        if (this.dialogTitle === '修改') method = 'put'
        ajax({
          method,
          url,
          data
        })
          .then((res) => {
            this.$message.success('操作成功')
            this.getRoleList()
            this.dialogVisible = false
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      })
    },
    isChecked (model, item) { // 判断选择框是否勾选
      const result = this.roleRouterList.find((n) => {
        return n.model === model
      })
      if (result) {
        return result.methods.includes(item)
        // 是否有该方法
      } else {
        return false
      }
    },
    btnRouter () { // 分配路由按钮事件
      ajax({
        method: 'put',
        traditional: true,
        url: '/role/routerlist',
        data: { _id: this.currentRoleId, routerList: JSON.stringify(this.roleRouterList) }
      })
        .then((res) => {
          this.$message.success('操作成功')
          this.routerDialogVisible = false
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    changeRouter (checked, model, item) {
      const result = this.roleRouterList.find((n) => {
        return n.model === model
      })
      if (result) {
        if (checked) {
          result.methods.push(item)
        } else {
          result.methods.splice(result.methods.findIndex(n => n === item), 1)
          if (result.methods.length === 0) {
            this.roleRouterList.splice(this.roleRouterList.findIndex(n => n.model === model), 1)
          }
        }
      } else {
        this.roleRouterList.push({ model, methods: [item] })
      }
    },
    async openMenuDialog (id) {
      this.menuDialogVisible = true
      this.currentRoleId = id
      try {
        let result = await ajax({
          method: 'get',
          url: `/role/${id}`
        })
        // if (result.data.menuList) {
        //   for (const item of result.data.menuList) {
        //     if (item.children) {
        //       for (const chl of item.children) {
        //         this.defMenu.push(chl.router)
        //       }
        //     }
        //   }
        // }
        this.defMenu = result.data.UIrouter
        result = await ajax({
          method: 'get',
          url: '/role/systemmenu'
        })
        this.systemMenuList = result.data
      } catch (err) {
        this.$message.error(err.msg)
      }
    },
    resetMenuForm () {
      this.defMenu = []
      this.systemMenuList = []
      this.currentRoleId = ''
    },
    async btnMenu () {
      try {
        await ajax({
          method: 'put',
          url: '/role/menulist',
          data: {
            _id: this.currentRoleId,
            UIrouter: JSON.stringify(this.$refs.refTree.getCheckedKeys().filter((item) => item != null))
          }
        })
        this.$message.success('操作成功')
        this.menuDialogVisible = false
      } catch (err) {
        this.$message.error(err.msg)
      }
    }
  },
  filters: {
    getMethodsName (methods) {
      switch (methods) {
        case 'get':
          return '查'
        case 'put':
          return '改'
        case 'post':
          return '增'
        case 'delete':
          return '删'
      }
    }
  }
}
</script>
