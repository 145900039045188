<template>
  <el-table-column width="70"
                   prop="status"
                   align="center"
                   label="状态">
    <template slot-scope="scope">
      <el-switch @change="enableChange(scope.row)"
                 :disabled="isDisable(model,'put')"
                 v-model="scope.row.enable">
      </el-switch>
    </template>
  </el-table-column>
</template>
<script>
import isDisable from 'mixins/isDisable.js'
import ajax from 'utils/ajax'
export default {
  mixins: [isDisable],
  props: {
    model: {
      type: String,
      required: true
    }
  },
  methods: {
    enableChange (row) {
      ajax({
        method: 'put',
        url: `/${this.model}/${row._id}/${row.enable}`
      }).then((res) => {
        this.$message.success('更新成功')
      })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    }
  }
}
</script>
