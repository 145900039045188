<template>
  <div>
    <sh-bread first="权限管理"
              second="在线用户"></sh-bread>
    <el-card class="box-card">
      <el-input placeholder="输入用户名"
                v-model="searchname"
                size="medium"
                suffix-icon="el-icon-search">
      </el-input>
      <el-button type="primary"
                 size="medium"
                 :disabled="isDisable('user','get')"
                 @click="serach()">搜索</el-button>
      <el-button type="danger"
                 size="medium"
                 :disabled="isDisable('user','delete')"
                 @click="clear()">清空</el-button>
      <div class="tagGroup">
        <el-tag ref="tag"
                type="info"
                :effect="item.effect"
                v-for="item in users"
                :key="item.name"
                @close="tagClose(item.name)"
                :closable="!isDisable('user','delete')">
          {{item.name}}
        </el-tag>
      </div>
    </el-card>
  </div>
</template>
<script>
import isDisable from 'mixins/isDisable.js'
import ajax from 'utils/ajax'
export default {
  mixins: [isDisable],
  data () {
    return {
      users: [],
      searchname: ''
    }
  },
  created () {
    this.getUser()
  },
  methods: {
    serach () {
      this.users = this.users.map(n => {
        n.effect = 'light'
        return n
      })
      this.users.forEach(element => {
        if (element.name === this.searchname) {
          element.effect = 'dark'
        }
      })
    },
    getUser () {
      ajax({
        method: 'get',
        url: '/user/online'
      }).then((res) => {
        this.users = res.data.map(n => { // 重新定义数组，增加effect属性
          const o = {}
          o.name = n
          o.effect = 'light'
          return o
        })
      })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    clear () {
      this.$confirm('是否要将所有用户强制离线，并且当前已登录帐号也会强制下线？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ajax({
          method: 'delete',
          url: 'user/online'
        }).then((res) => {
          this.$message.success('删除成功')
          this.getUser()
        })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      }
      ).catch(() => {

      })
    },
    tagClose (name) { // 删除词条
      this.$confirm('是否要将该用户强制离线？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ajax({
          method: 'delete',
          url: `user/online/${name}`
        }).then((res) => {
          this.$message.success('删除成功')
          this.getUser()
        })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      }
      ).catch(() => {

      })
    }
  }
}
</script>
<style lang="less" scoped>
.el-input {
  width: 200px;
  margin-right: 20px;
}
.tagGroup {
  margin-top: 15px;
}
.el-tag {
  flex-grow: 1;
  margin: 3px 5px;
  cursor: pointer;
}
</style>
