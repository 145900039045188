<template>
  <div>
    <sh-bread first="权限管理"
              second="部门管理"></sh-bread>
    <el-card class="box-card">
      <el-button @click="openDialog()"
                 :disabled="isDisable('department','post')"
                 type="primary"
                 size="medium">添加</el-button>
      <sh-table :loading="loading"
                :dataList="departmentList">
        <el-table-column type="index"
                         label="#">
        </el-table-column>
        <el-table-column prop="name"
                         label="名字">
        </el-table-column>
        <el-table-column prop="phone"
                         label="联系电话">
        </el-table-column>
        <col-sort />
        <col-createuser />
        <col-createtime />
        <col-modifyuser />
        <col-modifytime />
        <col-enable model="department" />
        <col-excute model="department"
                    @openEditDialog="openDialog"
                    @removeByID="bind" />
      </sh-table>
    </el-card>
    <!--弹出的对话框-->
    <el-dialog :title="dialogTitle"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               width="40%"
               @close="resetDepartmentFrom">
      <el-form label-width="80px"
               :model="departmentForm"
               :rules="departmentRule"
               ref="departmentRef">
        <el-form-item label="名称"
                      prop="name">
          <el-col :span="12">
            <el-input size="small"
                      v-model="departmentForm.name"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="联系电话"
                      prop="phone">
          <el-col :span="12">
            <el-input size="small"
                      v-model="departmentForm.phone"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="排序"
                      prop="sort">
          <el-col :span="12">
            <el-input size="small"
                      v-model.number="departmentForm.sort"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="可用"
                      prop="enable">
          <el-switch v-model="departmentForm.enable">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="btnOK">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ajax from 'utils/ajax'
import isDisable from 'mixins/isDisable.js'
import { EmptyPhoneMobile } from 'utils/regexp'
export default {
  mixins: [isDisable],
  data () {
    const validateSort = (rule, value, callback) => {
      if (!Number.isInteger(value)) callback(new Error('请输入数字值'))
      else callback()
    }
    const validataPhone = (rule, value, callback) => {
      if (EmptyPhoneMobile.test(value)) callback()
      else callback(new Error('电话格式不正确'))
    }
    return {
      departmentList: [], // 数据列表
      dialogVisible: false, // 对话框是否展示
      dialogTitle: '', // 对话框的标题
      loading: false,
      departmentForm: {
        name: '',
        sort: 0,
        phone: '',
        enable: true
      }, // 对话框绑定的对象
      departmentRule: {
        name: { required: true, message: '请输入部门名', trigger: 'blur' },
        sort: [{ validator: validateSort, trigger: 'blur' }],
        phone: [{ validator: validataPhone, trigger: 'blur' }]
      }
    }
  },
  mounted () {
    this.bind()
  },
  methods: {
    resetDepartmentFrom () { // 重置表单
      this.$refs.departmentRef.resetFields()
    },
    openDialog (id) { // 打开对话框
      if (id) { // 编辑状态
        this.dialogTitle = '修改'
        ajax({
          method: 'get',
          url: `/department/${id}`
        })
          .then((res) => {
            this.departmentForm = res.data
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      } else {
        delete (this.departmentForm._id) // 添加时候不能有_id
        this.dialogTitle = '添加' // 添加状态
      }
      this.dialogVisible = true
    },
    btnOK () {
      this.$refs.departmentRef.validate((valid) => {
        if (!valid) return
        const url = '/department'
        const data = this.departmentForm
        let method = 'post'
        if (this.dialogTitle === '修改') method = 'put'
        ajax({
          method,
          url,
          data
        })
          .then((res) => {
            this.$message.success('操作成功')
            this.bind()
            this.dialogVisible = false
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      })
    },
    bind () {
      this.loading = true
      ajax({
        method: 'get',
        url: '/department'
      }).then((res) => {
        this.loading = false
        this.departmentList = res.data
      })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    }
  }
}
</script>
