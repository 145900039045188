<template>
  <div>
    <sh-bread first="网站管理"
              second="点赞"></sh-bread>
    <el-card class="box-card">
      <el-input class="search"
                placeholder="邮箱，文章ID"
                v-model="query"
                size="medium"
                clearable
                suffix-icon="el-icon-search">
      </el-input>
      <el-button type="primary"
                 size="medium"
                 :disabled="isDisable('zan','get')"
                 @click="bind()">搜索</el-button>
      <sh-table :loading="loading"
                :dataList="zanList">
        <el-table-column prop="article.title"
                         label="标题">
        </el-table-column>
        <el-table-column prop="useremail"
                         label="邮箱">
        </el-table-column>
        <col-createuser />
        <col-createtime />
        <col-excute model="zan"
                    width="200px"
                    :showEdit=false
                    @removeByID="bind">
        </col-excute>
      </sh-table>
      <el-pagination background
                     layout="prev, pager, next, jumper"
                     :page-size=pagesize
                     @current-change="handleCurrentChange"
                     :total=allcount>
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import ajax from 'utils/ajax'
import isDisable from 'mixins/isDisable.js'
export default {
  data () {
    return {
      zanList: [],
      pagesize: 10,
      pagenum: 1,
      allcount: 0,
      loading: false,
      query: ''
    }
  },
  mounted () {
    this.bind()
  },
  methods: {
    bind () {
      this.loading = true
      let url = `/zan/${this.pagenum}/${this.pagesize}`
      if (this.query !== '') {
        url = `/zan/${this.pagenum}/${this.pagesize}?key=${this.query}`
      }
      ajax({
        method: 'get',
        url
      }).then((res) => {
        this.loading = false
        this.zanList = res.data.list
        this.allcount = res.data.allcount
      })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    handleCurrentChange (val) {
      this.pagenum = val
      this.bind()
    }
  },
  mixins: [isDisable]
}
</script>
<style lang="less" scoped>
.search {
  width: 250px;
  margin-right: 20px;
}
</style>
