<template>
  <div>
    <sh-bread first="网站管理"
              second="推荐文章"></sh-bread>
    <el-card class="box-card">
      <el-button @click="openDialog()"
                 :disabled="isDisable('recommend','post')"
                 type="primary"
                 size="medium">添加</el-button>
      <sh-table :loading="loading"
                :dataList="recommendlist">
        <el-table-column type="index"
                         label="#">
        </el-table-column>
        <el-table-column width="280"
                         align="center"
                         label="图片">
          <template slot-scope="scope">
            <el-image style="width: 250px; height: 100px"
                      :src="scope.row.img"
                      @click="openFullImg(scope.row.img)"
                      fit="fill"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="title"
                         label="标题">
        </el-table-column>
        <col-sort />
        <col-createuser />
        <col-createtime />
        <col-modifyuser />
        <col-modifytime />
        <col-enable model="recommend" />
        <col-excute model="recommend"
                    @openEditDialog="openDialog"
                    @removeByID="bind" />
      </sh-table>
    </el-card>
    <!--弹出的对话框-->
    <el-dialog :title="dialogTitle"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               width="30%"
               @close="resetRecommendFrom">
      <el-form label-width="80px"
               :model="recommendForm"
               :rules="recommendRule"
               ref="recommendRef">
        <el-form-item label="图片"
                      prop="img">
          <el-col :span="20">
            <el-upload :before-upload="beforeAvatarUpload"
                       ref="upload"
                       :show-file-list="false"
                       :auto-upload="true"><img v-if="recommendForm.img"
                   :src="recommendForm.img"
                   class="avatar">
              <i v-else
                 class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip"
                   class="el-upload-tip">只能jpg文件，不超过1m，宽不小于400，高不小于160，长宽比2.5</div>
            </el-upload>
          </el-col>
        </el-form-item>
        <el-form-item label="标题"
                      prop="title">
          <el-col :span="20">
            <el-input size="small"
                      v-model="recommendForm.title"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="链接"
                      prop="link">
          <el-col :span="20">
            <el-input size="small"
                      placeholder="必须是http开头的完整路径"
                      v-model="recommendForm.link"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="排序"
                      prop="sort">
          <el-col :span="4">
            <el-input size="small"
                      v-model.number="recommendForm.sort"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="可用"
                      prop="enable">
          <el-switch v-model="recommendForm.enable">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="btnOK">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ajax from 'utils/ajax'
import isDisable from 'mixins/isDisable.js'
import { URL } from 'utils/regexp'
export default {
  mixins: [isDisable],
  created () {
    this.bind()
  },
  data () {
    const validateSort = (rule, value, callback) => {
      if (!Number.isInteger(value)) callback(new Error('请输入数字值'))
      else callback()
    }
    const validateURL = (rule, value, callback) => {
      if (URL.test(value)) callback()
      else callback(new Error('URL格式不正确'))
    }
    return {
      dialogVisible: false, // 对话框是否展示
      recommendlist: [],
      imageUrl: '',
      dialogTitle: '',
      recommendForm: {
        img: '',
        sort: 0,
        title: '',
        enable: true,
        link: ''
      }, // 对话框绑定的对象
      recommendRule: {
        img: { required: true, message: '请上传图片', trigger: 'blur' },
        title: { required: true, message: '请填写标题', trigger: 'blur' },
        sort: [{ validator: validateSort, trigger: 'blur' }],
        link: [{ validator: validateURL, trigger: 'blur' }]
      }
    }
  },
  methods: {
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 1
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 1MB!')
      }

      const img = new Image()
      img.onload = () => {
        const width = img.width
        const height = img.height
        const scale = (width / height).toFixed(1)
        if (width < 400 || height < 160 || scale !== '2.5') {
          this.$message.error('宽度不小于400，高度不小于160，且长宽比必须是5:2')
        } else {
          if (isJPG && isLt2M) {
            const formData = new FormData()
            formData.append('img', file) // key必须是img，否则上传不成功
            ajax({
              method: 'post',
              url: '/recommend/upload',
              data: formData,
              transformRequest: null // 必须加上这句把默认配置里的取消掉
            }
            )
              .then((res) => {
                this.recommendForm.img = res.data
              })
              .catch((err) => {
                this.$message.error(err.msg)
              })
          }
        }
      }
      img.src = window.URL.createObjectURL(file)
    },
    openFullImg (src) {
      this.$alert(`<img style="width:100%" object-fit:fill src='${src}' />`, '图片预览', {
        dangerouslyUseHTMLString: true,
        center: true
      })
    },
    resetRecommendFrom () { // 重置表单
      this.$refs.recommendRef.resetFields()
      this.imageUrl = ''
    },
    bind () {
      this.loading = true
      ajax({
        method: 'get',
        url: '/recommend'
      }).then((res) => {
        this.loading = false
        this.recommendlist = res.data
      })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    openDialog (id) { // 打开对话框
      if (id) { // 编辑状态
        this.dialogTitle = '修改'
        ajax({
          method: 'get',
          url: `/recommend/${id}`
        })
          .then((res) => {
            this.recommendForm = res.data
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      } else {
        delete (this.recommendForm._id) // 添加时候不能有_id
        this.dialogTitle = '添加' // 添加状态
      }
      this.dialogVisible = true
    },
    btnOK () {
      this.$refs.recommendRef.validate((valid) => {
        if (!valid) return
        const url = '/recommend'
        const data = this.recommendForm
        let method = 'post'
        if (this.dialogTitle === '修改') method = 'put'
        ajax({
          method,
          url,
          data
        })
          .then((res) => {
            this.$message.success('操作成功')
            this.bind()
            this.dialogVisible = false
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.el-image {
  cursor: pointer;
  width: 180px;
  height: 140px;
  margin: 0 auto;
  border: solid 1px #ccc;
}
.avatar-uploader-icon {
  font-size: 28px;
  border-radius: 6px;
  border: 1px dashed #d9d9d9;
  color: #8c939d;
  width: 400px;
  height: 160px;
  line-height: 160px;
  text-align: center;
}
.avatar-uploader-icon:hover {
  border-color: #409eff;
}
.avatar {
  width: 400x;
  height: 160px;
  display: block;
}
</style>
