// 表格常用列的封装
import Vue from 'vue'
import CreateTime from 'components/project/column/CreateTime'
import CreateUser from 'components/project/column/CreateUser'
import Excute from 'components/project/column/Excute'
import ModifyTime from 'components/project/column/ModifyTime'
import ModifyUser from 'components/project/column/ModifyUser'
import Enable from 'components/project/column/Enable'
import Sort from 'components/project/column/Sort'
import Table from 'components/project/column/Table'
import BreadCrumb from 'components/project/BreadCrumb'

Vue.use(CreateTime)
Vue.use(CreateUser)
Vue.use(Excute)
Vue.use(ModifyTime)
Vue.use(ModifyUser)
Vue.use(Enable)
Vue.use(Sort)
Vue.use(Table)
Vue.use(BreadCrumb)
