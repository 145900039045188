<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/welcome' }">仪表盘</el-breadcrumb-item>
    <el-breadcrumb-item>{{first}}</el-breadcrumb-item>
    <el-breadcrumb-item>{{second}}</el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  props: {
    first: {
      type: String,
      default: '一级目录'
    },
    second: {
      type: String,
      default: '二级目录'
    }
  }
}
</script>
