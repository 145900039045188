<template>
  <el-aside :width="isUnfold?'200px':'64px'">
    <el-switch v-model="isUnfold"
               active-color="#ccc"
               inactive-color="#282828">
    </el-switch>
    <el-menu default-active="2"
             unique-opened
             router
             :collapse="ifFold"
             :collapse-transition="false"
             background-color="#333744"
             text-color="#fff"
             active-text-color="#6699FF">
      <el-menu-item index="welcome">
        <i class="el-icon-s-home"></i>
        <span>仪表盘</span>
      </el-menu-item>
      <!--一级菜单-->
      <!-- icon的文字 -->
      <el-submenu v-for="item in menuList"
                  :index="item.des"
                  :key="item.des">
        <!-- icon的文字 -->
        <template slot="title">
          <i :class="item.icon"></i>
          <span>{{item.des}}</span>
        </template>
        <!-- 以下是二级菜单 -->
        <el-menu-item :key="chl.des"
                      v-for="chl in item.children"
                      :index="chl.router"><i class="el-icon-menu"></i>{{chl.des}}</el-menu-item>
      </el-submenu>

    </el-menu>
  </el-aside>
</template>

<script>
import { decrypt } from 'utils/crypto'
export default {
  data () {
    return {
      isUnfold: true,
      menuList: JSON.parse(decrypt(window.sessionStorage.getItem('menuList')))
    }
  },
  computed: {
    ifFold () {
      return !this.isUnfold
    }
  }
}
</script>

<style lang="less" scoped>
.el-aside {
  background-color: #333744;
  .el-menu {
    border-right: solid 1px #333744;
  }
  .el-switch {
    margin: 15px 0 15px 18px;
  }
}
</style>
