import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  menuList: [],
  routerList: [],
  UIrouter: []
}

export default new Vuex.Store({
  state,
  mutations: {
    setmenu (state, payload) {
      // 设置当前登录用户的权限菜单  // 由于vuex是保存在内存中，页面刷新后 就不存在了。所以弃用
      state.menuList = payload.menuList
      state.routerList = payload.routerList
      state.UIrouter = payload.UIrouter
    }
  }
})
