import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from 'views/home/Login'
import Index from 'views/home/Index'
import Welcome from 'views/home/Welcome'
import Defartment from 'views/department/Index'
import Sensitiveword from 'views/sensitiveword/Index'
import User from 'views/user/Index'
import Role from 'views/role/Index'
import Online from 'views/user/online'
import BlackList from 'views/blacklist/Index'
import Catalog from 'views/catalog/Index'
import Tag from 'views/tag/Index'
import Article from 'views/article/Index'
import FriendLInk from 'views/friendlink/Index'
import Recommend from 'views/recommend/Index'
import StaticPage from 'views/staticpage/Index'
import Zan from 'views/zan/Index'
import Reply from 'views/reply/Index'
import ajax from 'utils/ajax'
import { decrypt } from 'utils/crypto'
import clear from 'utils/clear'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'welcome'
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: '登录页'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Index,
    children: [
      {
        path: '/welcome',
        name: 'welcome',
        component: Welcome,
        meta: {
          title: '仪表盘'
        }
      },
      {
        path: '/department',
        name: 'department',
        component: Defartment,
        meta: {
          title: '部门管理'
        }
      },
      {
        path: '/sensitiveword',
        name: 'sensitiveword',
        component: Sensitiveword,
        meta: {
          title: '敏感词管理'
        }
      },
      {
        path: '/user',
        name: 'user',
        component: User,
        meta: {
          title: '用户管理'
        }
      },
      {
        path: '/role',
        name: 'role',
        component: Role,
        meta: {
          title: '角色管理'
        }
      },
      {
        path: '/user/online',
        name: 'online',
        component: Online,
        meta: {
          title: '在线用户'
        }
      },
      {
        path: '/blacklist',
        name: 'blacklist',
        component: BlackList,
        meta: {
          title: '黑名单'
        }
      },
      {
        path: '/catalog',
        name: 'catalog',
        component: Catalog,
        meta: {
          title: '文章目录'
        }
      },
      {
        path: '/tag',
        name: 'tag',
        component: Tag,
        meta: {
          title: '文章标签'
        }
      },
      {
        path: '/article',
        name: 'article',
        component: Article,
        meta: {
          title: '文章列表'
        }
      },
      {
        path: '/friendlink',
        name: 'friendlink',
        component: FriendLInk,
        meta: {
          title: '友情链接'
        }
      },
      {
        path: '/recommend',
        name: 'recommend',
        component: Recommend,
        meta: {
          title: '推荐文章'
        }
      },
      {
        path: '/staticpage',
        name: 'staticpage',
        component: StaticPage,
        meta: {
          title: '标识页'
        }
      },
      {
        path: '/zan',
        name: 'zan',
        component: Zan,
        meta: {
          title: '点赞管理'
        }
      },
      {
        path: '/reply',
        name: 'reply',
        component: Reply,
        meta: {
          title: '评论管理'
        }
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  if (to.path === '/login') {
    return next()
  } else {
    if (
      !window.sessionStorage.getItem('apiid') ||
      !window.sessionStorage.getItem('apikey')
    ) {
      clear()
      return next('/login')
      // 如果本地没有存储apikey，那就要用户强制登录，页面只要关闭就要重新登录
    }
  }
  if (!window.localStorage.getItem('access_token')) {
    refreshToken(next)
  } else {
    const createtime = Number.parseInt(
      window.localStorage.getItem('createtime')
    )
    const expiresIn = Number.parseInt(window.localStorage.getItem('expiresIn'))
    const currtime = Math.floor(Date.now() / 1000)
    if (expiresIn + createtime < currtime + expiresIn / 2) {
      // 如果超过一半时间
      refreshToken(next)
    } else {
      const UIrouter = JSON.parse(
        decrypt(window.sessionStorage.getItem('UIrouter'))
      ) // 判断用户是有此路由的权限，避免用户直接输入浏览器访问
      if (to.path.toLowerCase() === '/welcome' || UIrouter.includes(to.path)) {
        next()
      } else {
        Vue.prototype.$message.warning('非法进入')
        clear()
        next('/login')
      }
    }
  }
})

// 刷新token
function refreshToken (next) {
  const id = window.sessionStorage.getItem('apiid')
  const apikey = decrypt(window.sessionStorage.getItem('apikey'))
  ajax({
    method: 'get',
    url: `/common/token?id=${id}&key=${apikey}`
  })
    .then((res) => {
      window.localStorage.setItem('access_token', res.data.access_token)
      window.localStorage.setItem('createtime', res.data.createtime)
      window.localStorage.setItem('expiresIn', res.data.expiresIn)
      next()
    })
    .catch((err) => {
      Vue.prototype.$message.error(err.msg)
    })
}
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
} // 避免重复路由 报错

export default router
