<template>
	<div id="editor"></div>
</template>
<script>
	import W from 'wangeditor'
	import OSS from 'ali-oss'
	import Vue from 'vue'
	import random from 'string-random'
	// import ajax from 'utils/ajax'
	// import hljs from 'highlight.js'
	export default {
		data() {
			return {
				editor: null
			}
		},
		mounted() {
			const editor = new W('#editor')
			const {
				PanelMenu,
				Panel
			} = W

			// 第一，菜单 class ，Button 菜单继承 BtnMenu class
			class SourceMenu extends PanelMenu {
				constructor(editor) {
					const $elem = W.$(
						`<div class="w-e-menu">
                <i title="查看源码" class="el-icon-view"></i>
          </div>`
					)
					super($elem, editor)
				}

				// 重点在这里，创建面板
				createPanel() {
					const panel = new Panel(this, {
						width: 600,
						height: 0, // 0代表根据tab自适应高度
						// panel 中可包含多个 tab
						tabs: [{
								// tab 的标题
								title: '查看源码',
								// 模板，注意textarea和button的id，自己定义，不要与其他标签id冲突就行
								tpl: `<div><textarea id="txtSource" type="text" 
                  class="wang-textarea" placeholder="" style="height: 340px">
                  </textarea><div class="w-e-button-container">
                   <button type="button" id="btnSource" class="right">
                   确认</button></div></div>`,
								// 事件绑定
								events: [{
									// <button>的id
									selector: '#btnSource',
									type: 'click',
									// 点击button实现的方法
									fn: () => {
										// 使编辑的内容与源码textarea相同
										editor.txt.html(document.getElementById('txtSource')
											.value)
										// 返回 true，表示该事件执行完之后，panel 要关闭。否则 panel 不会关闭
										return true
									}
								}]
							} // tab end
						]
					})
					panel.create()
				}

				// 菜单查看源码点击事件，将编辑的源码赋值给textarea
				clickHandler() {
					this.createPanel(editor.txt.html())
					document.getElementById('txtSource').value = editor.txt.html()
				}

				tryChangeActive() {
					this.active()
				}
			}
			const menuKey = 'alertMenuKey' // 菜单 key ，各个菜单不能重复
			W.registerMenu(menuKey, SourceMenu) // 全局注册菜单
			// 用element组件的提示框代替默认的alert提示框
			editor.config.customAlert = (s, t) => {
				switch (t) {
					case 'success':
						Vue.prototype.$message.success(s)
						break
					case 'info':
						Vue.prototype.$message.info(s)
						break
					case 'warning':
						Vue.prototype.$message.warning(s)
						break
					case 'error':
						Vue.prototype.$message.error(s)
						break
					default:
						Vue.prototype.$message.info(s)
						break
				}
			}
			// 配置菜单，你可以设置设置不需要的菜单如下
			// editor.config.excludeMenus = ['emoticon', 'video']
			editor.config.menus = [
				'head',
				'bold',
				'strikeThrough',
				'link',
				'italic',
				'foreColor',
				'backColor',
				'quote',
				'emoticon',
				'image',
				'video',
				'table',
				'code',
				'undo',
				'redo'
			]
			// 插入代码需要显示的语言
			editor.config.languageType = [
				'C#',
				'PowerShell',
				'JavaScript',
				'Html',
				'CSS',
				'Nginx',
				'JSON',
				'Plain text',
				'XML',
				'SQL',
				'Markdown',
				'Python'
			]
			// editor.highlight = hljs
			editor.config.languageTab = '    '
			// editor.highlight = hljs
			// 配置粘贴文本的内容处理
			editor.config.pasteTextHandle = function(pasteStr) {
				// 对粘贴的文本进行处理，然后返回处理后的结果
				return pasteStr.replace(/<\/?.+?>/g, '</p><p>')
					.replace(/(<\/p><p>)+/g, '</p><p>')
			}
			editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif']
			editor.config.uploadImgMaxSize = 1024 * 1024 * 2 // 2M
			editor.config.uploadImgMaxLength = 9 // 默认上传几张
			// 2021.11.24开始使用oss上传，不再使用node服务端
			// editor.config.customUploadImg = function (resultFiles, insertImgFn) {
			//   const formData = new FormData()
			//   formData.append('img', resultFiles[0]) // key必须是img，否则上传不成功
			//   formData.append('type', 'article')
			//   ajax({
			//     method: 'post',
			//     url: '/article/upload',
			//     data: formData,
			//     transformRequest: null // 必须加上这句把默认配置里的取消掉
			//   }
			//   )
			//     .then((res) => {
			//       insertImgFn(res.data)
			//     })
			//     .catch((err) => {
			//       alert(err.msg)
			//     })
			// }
			// 跨域错误查看https://help.aliyun.com/document_detail/44199.html
			// 具体值需要去阿里云控制台获取
			const client = new OSS({
				// // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
				// region: 'oss-cn-shanghai',使用别用无需此节点
				// // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
				// 以下是RAM账户hkzj的ID和secret
				accessKeyId: 'LTAI5t7nz86YqfV17Rc9TVYi',
				accessKeySecret: 'AXjC7EGfnskca5C359jB6HhFwKMJPS',
				bucket: 'shuanghei',
				endpoint: 'f.shuanghei.com', // 使用别名访问
				cname: true
			})

			editor.config.customUploadImg = (resultFiles, insertImgFn) => {
				// resultFiles 是 input 中选中的文件列表
				// insertImgFn 是获取图片 url 后，插入到编辑器的方法
				resultFiles.forEach((item) => {
					const {
						filePath,
						fileExt
					} = createFileUploadPath('newsimg', item)
					client.put(filePath, item)
						.then((res) => {
							if (fileExt !== 'gif') { // gif不处理，因为处理后不能正常显示
								// 上传图片，返回结果，将图片插入到编辑器中
								client.processObjectSave(
									filePath,
									filePath,
									'style/shuanghei',
									'shuanghei'
								).then(() => {
									res.url = res.url.replace('https', 'http').replace(
										'http', 'https')
									insertImgFn(res.url)
								})
							} else {
								insertImgFn(res.url)
							}
						}).catch((err) => {
							console.log(err)
						})
				})
			}
			editor.config.uploadVideoMaxSize = 1 * 1024 * 1024 * 500 // 500m
			editor.config.customUploadVideo = (resultFiles, insertVideoFn) => {
				const videoPath = createFileUploadPath('newsvideo', resultFiles[0]).filePath
				client.put(videoPath, resultFiles[0])
					.then(function(res) {
						// 上传视频，返回结果，将视频插入到编辑器中
						insertVideoFn(res.url)
					}).catch(function(err) {
						console.log(err)
					})
			}
			// 根据选择的文件，拼凑上传最终路径
			const createFileUploadPath = (dir, file) => {
				// const dir = 'newsimg' oss存储的目录
				const fileExt = file.name.split('.')[1] // 文件扩展名
				const date = new Date()
				return {
					filePath: `${dir}/${date.getFullYear()}/${date.getMonth() + 1}/${random(12)}.${fileExt}`,
					fileExt
				}
			}
			editor.create()
			this.editor = editor
		},
		beforeDestroy() {
			// 调用销毁 API 对当前编辑器实例进行销毁
			this.editor.destroy()
			this.editor = null
		},
		methods: {
			getHtml() {
				return this.editor.txt.html()
			},
			setHtml(val) {
				this.editor.txt.html(val)
			},
			getTxt() {
				return this.editor.txt.text()
			}
		}
	}
</script>
