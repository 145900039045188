import { decrypt } from 'utils/crypto'

export default {
  computed: {
    isDisable () {
      return function (model, method) {
        const item = JSON.parse(
          decrypt(window.sessionStorage.getItem('routerList'))
        ).find((n) => {
          return n.model === model
        })
        if (item) {
          return !item.methods.includes(method)
        }
        return true
      }
    }
  }
}
