/**
 * 允许空的手机号码或固话
 */
export const EmptyPhoneMobile = /^\s*$|^13\d{9}$|^14\d{9}$|^15\d{9}$|^17\d{9}$|^18\d{9}$|^19\d{9}$|^0\d{10}$|^0\d{2}-\d{8}$/

/**
 * 不允许空的手机号码或固话
 */
export const PhoneMobile = /^13\d{9}$|^14\d{9}$|^15\d{9}$|^17\d{9}$|^18\d{9}$|^19\d{9}$|^0\d{10}$|^0\d{2}-\d{8}$/

/**
 * 允许空的手机号码
 */
export const EmptyMobile = /^\s*$|^13\d{9}$|^14\d{9}$|^15\d{9}$|^17\d{9}$|^18\d{9}$|^19\d{9}$/

/**
 * 不允许空手机号码
 */
export const Mobile = /^13\d{9}$|^14\d{9}$|^15\d{9}$|^17\d{9}$|^18\d{9}$|^19\d{9}$/

/**
 * 允许空邮箱
 */
export const EmptyEmail = /^\s*$|^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/

/**
 * 邮箱
 */
export const Email = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/

/**
 * 用户名，需另外判断不全是数字
 */
export const UserName = /^[a-z0-9]{4,20}$/i

/**
 * 密码，必须包含大小写字母和数字8-20位
 */
export const Password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,20}$/

/**
 * IP地址(ipv4/ipv6)
 */
export const IP = /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/

/**
 * URL
 */
export const URL = /^(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&amp;:/~+#]*[\w\-@?^=%&amp;/~+#])?$/
