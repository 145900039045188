import Router from '../router'
export default () => {
  window.localStorage.removeItem('access_token')
  window.localStorage.removeItem('createtime')
  window.localStorage.removeItem('expires_in')
  window.sessionStorage.removeItem('apiid')
  window.sessionStorage.removeItem('apikey')
  window.sessionStorage.removeItem('username')
  window.sessionStorage.removeItem('menuList')
  window.sessionStorage.removeItem('routerList')
  window.sessionStorage.removeItem('UIrouter')
  Router.push('/login')
}
