<template>
  <el-table-column prop="createUser"
                   label="创建人"
                   :width="width">
  </el-table-column>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: '100'
    }
  }
}
</script>
