import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './plugins/shuanghei.js' // 导入自己封装的 elment组件
// 导入normlize各浏览器格式化标准
import 'assets/css/normalize.css'
// 导入全局通用样式
import 'assets/css/common.css'
// 导入图标库
import 'assets/css/font.css'
// 避免chorme浏览器出现警告信息
import 'default-passive-events'
// 全局引用md5加密
import md5 from 'js-md5'
// 复制到剪贴版
import VueClipboard from 'vue-clipboard2'

import store from './store'
Vue.use(VueClipboard)
Vue.prototype.$md5 = md5
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
