<template>
  <el-table-column prop="modifyUser"
                   label="修改人"
                   :width=width>
  </el-table-column>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: '100'
    }
  }
}
</script>
