<template>
  <div>
    <sh-bread first="文章管理"
              second="目录管理"></sh-bread>
    <el-card class="box-card">
      <el-button @click="openDialog()"
                 :disabled="isDisable('catalog','post')"
                 type="primary"
                 size="medium">添加</el-button>
      <sh-table :loading="loading"
                :dataList="cataloglist">
        <el-table-column type="index"
                         label="#">
        </el-table-column>
        <el-table-column prop="name"
                         label="名字">
        </el-table-column>
        <el-table-column prop="router"
                         label="路由">
        </el-table-column>
        <col-sort />
        <col-createuser />
        <col-createtime />
        <col-modifyuser />
        <col-modifytime />
        <col-enable model="catalog" />
        <col-excute model="catalog"
                    @openEditDialog="openDialog"
                    @removeByID="bind" />
      </sh-table>
    </el-card>
    <!--弹出的对话框-->
    <el-dialog :title="dialogTitle"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               width="30%"
               @close="resetCatalogFrom">
      <el-form label-width="80px"
               :model="catalogForm"
               :rules="catalogRule"
               ref="catalogRef">
        <el-form-item label="名称"
                      prop="name">
          <el-col :span="6">
            <el-input size="small"
                      v-model="catalogForm.name"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="路由"
                      prop="router">
          <el-col :span="20">
            <el-input size="small"
                      placeholder="请输入合理的英文单词"
                      v-model="catalogForm.router"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="排序"
                      prop="sort">
          <el-col :span="4">
            <el-input size="small"
                      v-model.number="catalogForm.sort"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="可用"
                      prop="enable">
          <el-switch v-model="catalogForm.enable">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="btnOK">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ajax from 'utils/ajax'
import isDisable from 'mixins/isDisable.js'
export default {
  mixins: [isDisable],
  created () {
    this.bind()
  },
  data () {
    const validateSort = (rule, value, callback) => {
      if (!Number.isInteger(value)) callback(new Error('请输入数字值'))
      else callback()
    }
    return {
      dialogVisible: false, // 对话框是否展示
      dialogTitle: '', // 对话框的标题
      cataloglist: [],
      catalogForm: {
        name: '',
        sort: 0,
        enable: true,
        router: ''
      }, // 对话框绑定的对象
      catalogRule: {
        name: { required: true, message: '请输入目录名', trigger: 'blur' },
        sort: [{ validator: validateSort, trigger: 'blur' }],
        router: { required: true, message: '请输入路由', trigger: 'blur' }
      }
    }
  },
  methods: {
    resetCatalogFrom () { // 重置表单
      this.$refs.catalogRef.resetFields()
    },
    bind () {
      this.loading = true
      ajax({
        method: 'get',
        url: '/catalog'
      }).then((res) => {
        this.loading = false
        this.cataloglist = res.data
      })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    openDialog (id) { // 打开对话框
      if (id) { // 编辑状态
        this.dialogTitle = '修改'
        ajax({
          method: 'get',
          url: `/catalog/${id}`
        })
          .then((res) => {
            this.catalogForm = res.data
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      } else {
        delete (this.catalogForm._id) // 添加时候不能有_id
        this.dialogTitle = '添加' // 添加状态
      }
      this.dialogVisible = true
    },
    btnOK () {
      this.$refs.catalogRef.validate((valid) => {
        if (!valid) return
        const url = '/catalog'
        const data = this.catalogForm
        let method = 'post'
        if (this.dialogTitle === '修改') method = 'put'
        ajax({
          method,
          url,
          data
        })
          .then((res) => {
            this.$message.success('操作成功')
            this.bind()
            this.dialogVisible = false
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      })
    }
  }
}
</script>
