import axios from 'axios'
import qs from 'qs'
import Vue from 'vue'
import Router from '../router'
import clear from 'utils/clear'
const ajax = axios.create({
  baseURL: 'https://api.shuanghei.com',
  // baseURL: 'http://127.0.0.1:7190',
  timeout: 5000,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})
ajax.defaults.transformRequest = (data) => {
  console.log(data)
  return qs.stringify(data)
  // console.log(data)
} // { arrayFormat: 'brackets' }, { indices: false } 暂时不需要
ajax.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem('access_token')
    config.headers.authorization = token // 请求时添加coken
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

ajax.interceptors.response.use(
  (res) => {
    return res.data
  },
  (err) => {
    // 这里可以预处理错误
    if (err.response.status === 401) {
      const { code } = err.response.data
      if (code === 10007) {
        // 此code是服务端 返回得错误编号，固定死
        clear()
        Router.push('/login')
        Vue.prototype.$message.error('帐号已在别处登录')
        return
      }
      if (code === 10006) {
        clear()
        Router.push('/login')
        Vue.prototype.$message.error('帐号已被管理员强制下线')
        return
      }
      if (code === 10003) {
        clear()
        Router.push('/login')
        Vue.prototype.$message.error('已超时，请重新登录')
        return
      }
    }
    return Promise.reject(err.response.data)
  }
)
export default ajax
